import React from "react";
import { Layout } from "antd";

export default () => {
	return (
		<Layout.Footer style={{ textAlign: "center" }}>
			All rights reserved Discount Loans 2020-2021 <br />
			Support & Maintenance by{" "}
			<a target={"_blank"} href={"https://studiomediaagency.com"} rel="noopener noreferrer">
				<span style={{ color: "#000", fontFamily: "brandon_textbold" }}>Studio Media Agency</span>
			</a>
		</Layout.Footer>
	);
};
