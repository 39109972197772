import { Link } from "react-router-dom";
import { Button } from "antd";
import Can from "../../roles/can";
import moment from "moment";
import React from "react";
import Redux from "../../redux";
import type { Filter } from "../../types/filter";
import { deleteUser, exportToInfinity } from "./actions";

export default (cities: Array<Filter>, steps: Array<Filter>, getColumnSearchProps: Function) => {
	const reduxState = Redux().store.getState();
	const stores = [...reduxState.stores].sort((a, b) => a.preName.localeCompare(b.preName));
	const activeFilter = reduxState.filter && reduxState.filter.length ? reduxState.filter : null;

	return [
		{
			key: "date",
			title: "Date",
			dataIndex: "date",
			defaultSortOrder: "descend",
			sorter: (a: any, b: any) => a.date - b.date,
			render: (date: any) => moment(date.toString(), "X").format("lll"),
		},
		{
			key: "source",
			title: "Source",
			dataIndex: "source",
			width: 180,
			filters: [
				{ text: "Website", value: "Website" },
				{ text: "Store Front", value: "Store Front" },
				{ text: "B2B Referral", value: "B2B Referral" },
			],
			onFilter: (value: Filter, record: any) => value === record.source,
			sorter: (a: any, b: any) => a.source.localeCompare(b.source),
			render: (source: any) => source,
		},
		{
			key: "store",
			title: "Store Assigned",
			dataIndex: "store",
			defaultFilteredValue: activeFilter,
			filters: stores.map(x => ({
				text: `${x.preName} ${x.name}`,
				value: x.name,
			})),
			onFilter: (value: Filter, record: Object) => {
				if (value.text === "NO STORE") {
					return !record;
				} else if (record) {
					return value === record.store;
				} else {
					return false;
				}
			},
			sorter: (a: any, b: any) => {
				if (a.store && b.store) {
					return a.store.localeCompare(b.store);
				} else {
					return null;
				}
			},
			render: (store: any) => {
				if (store) {
					return store;
				} else {
					return "-";
				}
			},
		},
		{
			key: "name",
			title: "Customer Name",
			dataIndex: "name",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
			...getColumnSearchProps("name"),
			render: (name, record) => <Link to={"/" + record.uid + "/information"}>{name}</Link>,
		},
		{
			key: "city",
			title: "City",
			dataIndex: "city",
			filters: cities,
			onFilter: (value: Filter, record: any) => value === record.city,
			sorter: (a: any, b: any) => {
				return a.city.localeCompare(b.city);
			},
			render: (city: any) => city,
		},
		{
			key: "step",
			title: "Step",
			dataIndex: "step",
			filters: steps,
			onFilter: (value: Filter, record: any) => value === record.step + 1,
			sorter: (a: any, b: any) => a.step - b.step,
			render: (step: any) => step + 1 + " / 10",
		},
		{
			key: "limit",
			title: "Credit Limit",
			render: (record: any) => {
				if (record.adminLoan > 0) {
					return "$" + record.adminLoan.toFixed();
				} else if (record.applicationLoan > 0) {
					return "$" + record.applicationLoan.toFixed();
				} else {
					return "NOT APPROVED";
				}
			},
		},
		{
			key: "notes",
			title: "Notes",
			dataIndex: "notes",
			width: "20%",
			...getColumnSearchProps("notes"),
		},
		{
			key: "updated",
			title: "Updated",
			dataIndex: "user",
			sorter: (a: any, b: any) => a.updated - b.updated,
			render: (record: any, user: any) => {
				if (user.updated) {
					return moment(user.updated.toString(), "X").format("lll");
				} else {
					return "Asd";
				}
			},
		},
		{
			key: "actions",
			title: "Actions",
			render: (record: any) => {
				return (
					<>
						<Link to={"/" + record.uid + "/information"} style={{ padding: 5 }}>
							<i className={"fa fa-eye"} />
						</Link>
						{record.step > 4 && record.step < 7 && !record.exported ? (
							<Button onClick={() => exportToInfinity(record.uid)} type={"link"} style={{ padding: 5 }}>
								<i className={"fa fa-file-export"} />
							</Button>
						) : null}
						<Can I={"delete"} an={"application"}>
							<Button onClick={() => deleteUser(record.uid)} type={"link"} style={{ padding: 5, color: "red" }}>
								<i className={"fa fa-trash-alt"} />
							</Button>
						</Can>
					</>
				);
			},
		},
	];
};
