import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { firestore } from "../../firebase";

export default (props: Object) => {
	const [loading, setLoading] = useState(false);
	// const [ID, setID] = useState("");
	const [dropdownStores, setDropdownStores] = useState([]);
	const [form] = Form.useForm();

	const getStores = () => {
		firestore
			.collection("finance-stores")
			.orderBy("digital", "asc")
			.get()
			.then(storesCollection => {
				const stores = storesCollection.docs.map(item => {
					return {
						docID: item.id,
						...item.data(),
					};
				});
				return setDropdownStores(stores);
			})
			.catch();
	};

	useEffect(getStores, []);

	const onFinish = async values => {
		setLoading(true);
		await firestore.collection("finance-referrals").add({
			...values,
			employee: props.employee,
			employeeName: props.employeeName,
			employeeStoreRef: null,
			storeRef: firestore.doc("finance-stores/" + values.store),
			source: "Digital",
			store: dropdownStores.find(x => x.docID === values.store),
			storeName: dropdownStores.find(x => x.docID === values.store)?.name,
			bonus: 0,
			status: "Pending",
			notes: values?.notes ?? null,
			timestamp: new Date(),
		});
		setLoading(false);
		props.refresh();
		props.onCancel();
		return form.resetFields();
	};

	return (
		<Modal
			forceRender
			title={"Add Referral"}
			visible={props.visible}
			onCancel={() => {
				props.onCancel();
				form.resetFields();
			}}
			footer={[
				<Button key={0} loading={loading} onClick={form.submit} type={"primary"}>
					{!props.form ? "Add Referral" : "Save"}
				</Button>,
				<Button key={1} onClick={props.onCancel}>
					Cancel
				</Button>,
			]}>
			<Form form={form} onFinish={onFinish} layout={"vertical"}>
				<Row gutter={12}>
					<Col span={12}>
						<Form.Item
							label={"Applicant's First Name"}
							name={"firstName"}
							rules={[
								{
									required: true,
									message: "Please input your name!",
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Applicant's Last Name"}
							name={"lastName"}
							rules={[
								{
									required: true,
									message: "Please input your name!",
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Applicant's Phone"}
							name={"phone"}
							rules={[
								{
									required: true,
									message: "Please input your phone #!",
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Assigned Store"}
							name={"store"}
							rules={[
								{
									required: true,
									message: "Please select a store",
								},
							]}>
							<Select>
								{dropdownStores
									.filter(x => x.name !== "Unassigned Digital")
									.map((store, index) => (
										<Select.Option key={index} value={store.docID}>
											{store.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label={"Notes (Referred by)"} name={"notes"}>
							<Input.TextArea rows={8} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
