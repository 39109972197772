import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Table, Typography } from "antd";
import axios from "axios";
import AppHeader from "../../components/appHeader";
import Footer from "../../components/footer";
import { columns } from "./columns";
import CheckLogsModal from "./modal";
import { url } from "./config";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";

const CheckLogs = () => {
	const stores = useSelector(state => state.stores);
	let searchInput;
	const [state, setState] = useState({
		checks: [],
		loading: false,
		visible: false,
		data: null,
		edit: null,
		searchText: "",
		searchedColumn: "",
	});

	const onComponentMountAsync = () => {
		setState({ ...state, loading: true, visible: false, edit: null });
		axios
			.get(url)
			.then(response => {
				return setState({ ...state, data: response.data, loading: false, edit: false, visible: false });
			})
			.catch();
	};

	useEffect(onComponentMountAsync, []);

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.currentTarget.value ? [e.currentTarget.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				?.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				// $FlowFixMe
				setTimeout(() => searchInput.select());
			}
		},
		render: text =>
			state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[state.searchText]}
					autoEscape
					textToHighlight={text?.toString()}
				/>
			) : (
				text
			),
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setState({
			...state,
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	const handleReset = clearFilters => {
		clearFilters();
		setState({ ...state, searchText: "" });
	};

	const manipulateData = async (data, option) => {
		if (option === "edit") {
			return setState({ ...state, edit: data, visible: true });
		}
		if (option === "delete") {
			await axios.delete(`${url}/${data}`);
			return onComponentMountAsync();
		}
		return null;
	};

	const close = () => {
		setState({ ...state, edit: null, visible: false });
	};

	return (
		<div style={{ backgroundColor: "#F1F2F5", minHeight: "100vh" }}>
			<AppHeader />
			<CheckLogsModal
				visible={state.visible}
				edit={state.edit}
				reload={() => onComponentMountAsync()}
				close={() => {
					close();
				}}
			/>
			<div style={{ paddingTop: 200, paddingLeft: 30, paddingRight: 30, backgroundColor: "#FFF", minHeight: "100vh" }}>
				<Row style={{ display: "flex", alignItems: "center" }}>
					<Col span={12}>
						<Typography.Title level={1}>Check Logs</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12}>
						<Button type="primary" onClick={() => setState({ ...state, visible: true, edit: null })}>
							Create
						</Button>
					</Col>
				</Row>
				<Table
					rowKey={({ id }) => id}
					loading={state.loading}
					dataSource={state.data}
					columns={columns(manipulateData, getColumnSearchProps, stores)}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default CheckLogs;
