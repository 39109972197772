import React from "react";
import async from "async";
import { Col, Button, Row, Table, Typography, Popconfirm } from "antd";
import UserModal from "./modal";
import { firestore } from "../../firebase";
import Footer from "../../components/footer";
import AppHeader from "../../components/appHeader";

type Props = {};
type State = {
	visible: boolean,
	loading: boolean,
	allUsers: Array<Object>,
	editableData: Array<Object> | null,
	editing: boolean,
};

class Users extends React.Component<Props, State> {
	state = {
		visible: false,
		loading: true,
		allUsers: [],
		editableData: null,
		editing: false,
	};

	componentDidMount(): void {
		firestore
			.collection("adminUsers")
			.orderBy("name", "asc")
			.onSnapshot(user => {
				const allUsers = [];
				async.eachOf(
					user.docs,
					(user, key, callback) => {
						allUsers.push({
							key: user.id,
							name: user.data().name,
							email: user.data().email,
							role: user.data().role,
						});
						callback();
					},
					() => {
						this.setState({ allUsers, loading: false });
					}
				);
			});
	}

	editableData = (data: Array<Object>) => {
		this.setState({ editableData: data, visible: true, editing: true });
	};

	render() {
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
			},
			{
				title: "Email",
				dataIndex: "email",
			},
			{
				title: "Action",
				key: "action",
				width: 200,
				render: (item, data) => {
					return (
						<Row>
							<Col span={12}>
								<Button onClick={() => this.editableData(data)}>Edit</Button>
							</Col>
							<Col span={12}>
								<Popconfirm
									title="Are you sure you want to delete this user?"
									okText="Yes"
									onConfirm={() => firestore.doc("adminUsers/" + data.key).delete()}>
									<Button type="danger">Delete</Button>
								</Popconfirm>
							</Col>
						</Row>
					);
				},
			},
		];
		return (
			<div style={{ backgroundColor: "#F1F2F5", minHeight: "100vh" }}>
				<AppHeader />
				<div style={{ paddingTop: 200, paddingLeft: 30, paddingRight: 30, backgroundColor: "#FFF", minHeight: "100vh" }}>
					<UserModal
						onCancel={() => this.setState({ visible: false })}
						editing={this.state.editing}
						data={this.state.editableData}
						visible={this.state.visible}
					/>
					<Row style={{ display: "flex", alignItems: "center" }}>
						<Col span={12}>
							<Typography.Title level={1}>Users</Typography.Title>
						</Col>
						<Col style={{ textAlign: "right" }} span={12}>
							<Button type="primary" onClick={() => this.setState({ visible: true })}>
								Create
							</Button>
						</Col>
					</Row>
					<Table bordered dataSource={this.state.allUsers} loading={this.state.loading} scroll={{ y: 600 }} pagination={false} columns={columns} />
				</div>
				<Footer />
			</div>
		);
	}
}

export default Users;
