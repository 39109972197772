import React from "react";
import { Col, Input, Modal, notification, Row, Select, Button } from "antd";
import { firebaseAdmin, firestore, auth } from "../../firebase";

type Props = {
	visible: boolean,
	editing: boolean,
	onCancel: Function,
	data: any,
};
type State = {
	name: string,
	email: string,
	role: string,
	password: string,
	confirm: string,
	editing: boolean,
	loading: boolean,
	uid: string,
};

class UserModal extends React.Component<Props, State> {
	state = {
		name: "",
		email: "",
		role: "",
		password: "",
		confirm: "",
		editing: false,
		loading: false,
		uid: "",
	};

	componentDidUpdate(oldProps: Props): void {
		const newProps = this.props;
		if (oldProps.data !== newProps.data) {
			this.setState({
				uid: this.props.data ? this.props.data.key : "",
				name: this.props.data ? this.props.data.name : "",
				email: this.props.data ? this.props.data.email : "",
				role: this.props.data ? this.props.data.role : "",
				editing: this.props.editing,
			});
		}
	}

	resetUserPassword = () => {
		auth.sendPasswordResetEmail(this.state.email)
			.then(() => notification.success({ message: "Success!", description: "The admin will receive an email to reset their password" }))
			.catch(err => {
				if (err) {
					return notification.error({ message: "Ooops!", description: err.message });
				}
				return null;
			});
	};

	createUser = async () => {
		const { name, uid, email, role, password, editing } = this.state;
		await this.setState({ loading: true });
		const user = auth.currentUser;
		if (editing) {
			await firestore
				.collection("adminUsers")
				.doc(uid)
				.update({
					name,
					email,
					role,
				});
			await this.setState({
				name: "",
				email: "",
				role: "",
				password: "",
				confirm: "",
				loading: false,
				editing: false,
			});
			await this.props.onCancel();
			notification.success({ message: "Success!", description: "Changes saved successfully!" });
			return user.updateProfile({
				email,
			});
		} else {
			if (!name || !email) {
				return notification.error({ message: "Ooops!", description: "Name or email cannot be blank." });
			}
			const user = await firebaseAdmin.createUserWithEmailAndPassword(email, password);
			const userID = user.user.uid;
			await firestore
				.collection("adminUsers")
				.doc(userID)
				.set({
					name,
					email,
					role,
				});
			await this.setState({
				name: "",
				email: "",
				role: "",
				password: "",
				confirm: "",
				loading: false,
				editing: false,
			});
			await this.props.onCancel();
			return notification.success({ message: "Success!", description: "User was created successfully!" });
		}
	};
	render() {
		return (
			<Modal
				title={"Create User"}
				onOk={this.createUser}
				visible={this.props.visible}
				confirmLoading={this.state.loading}
				onCancel={async () => {
					await this.props.onCancel();
					this.setState({
						name: "",
						email: "",
						role: "",
						password: "",
						confirm: "",
						loading: false,
						editing: false,
					});
				}}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<label>Name</label>
						<Input value={this.state.name} onChange={e => this.setState({ name: e.currentTarget.value })} />
					</Col>
					<Col span={12}>
						<label>Email</label>
						<Input value={this.state.email} onChange={e => this.setState({ email: e.currentTarget.value })} />
					</Col>
					<Col span={24}>
						<label>Role</label>
						<Select style={{ width: "100%" }} value={this.state.role} onChange={e => this.setState({ role: e })}>
							<Select.Option key={0} value="Admin">
								Admin
							</Select.Option>
							<Select.Option key={1} value="User">
								User
							</Select.Option>
						</Select>
					</Col>
					<Col span={12}>
						<Button onClick={() => this.resetUserPassword()}>Reset Password</Button>
					</Col>
				</Row>
			</Modal>
		);
	}
}

export default UserModal;
