import { Modal } from "antd";
import { firestore } from "../../firebase";
import type { State, Props } from "./types";

export default (state: State, props: Props): Promise<void> => {
	return new Promise(async resolve => {
		const admin = props.admin;
		const application = props.applications[0];

		// Check if ID and address have been verified
		if (state.status >= 5 && (!state.vId || !state.vResidence)) {
			error("Unverified Information", "ID and Address must be verified before changing to this step");
			return resolve();
		}

		// Check if mileage, vin and plates have been verified
		if (state.status >= 5 && (!admin.vMileage || !admin.vVin || !admin.vPlate)) {
			error("Unverified Vehicle", "You cannot approve a loan with unverified vehicle's mileage, VIN and Plates");
			return resolve();
		}

		// Confirm status change
		if (application.status !== 5 && state.status === 5) {
			// noinspection JSUnusedGlobalSymbols
			return Modal.confirm({
				title: "Please Confirm",
				content: "This will update the status and notify the client",
				okText: "Confirm",
				onOk: async () => {
					await save(state, props);
					return resolve();
				},
				onCancel: () => {
					return resolve();
				},
			});
		}

		// Check if customer ID and loan ID have been set, so the user can sign contract
		if (state.status === 8 && (!state.customerID || !state.loanID)) {
			error("Missing Information", "You must specify Infinity Customer ID & Loan ID to use this step");
			return resolve();
		}

		// Otherwise, just save
		await save(state, props);
		return resolve();
	});
};

function save(state: State, props: Props): Promise<void> {
	return new Promise(async resolve => {
		await firestore.doc("admin/" + props.uid).update({
			customerID: state.customerID ?? null,
			loanID: state.loanID ?? null,
			heard: state.heard ?? null,
			gender: state.gender ?? null,
			vId: state.vId,
			vResidence: state.vResidence,
			notes: state.notes ?? null,
		});
		await firestore.doc("users/" + props.uid).update({
			// Will start saving on users, eventually we will change it to ONLY users
			store: state.store ? props.stores.find(e => e.id === state.store) : null,
			firstName: state.firstName,
			lastName: state.lastName,
			phone: state.phone,
			ssn: state.ssn,
		});
		await firestore.doc("applications/" + props.uid).update({
			store: state.store ? props.stores.find(e => e.id === state.store) : null,
			active: state.active,
			status: state.status,

			homeAddress: state.homeAddress,
			homeCity: state.homeCity,
			homeState: state.homeState,
			homeZip: state.homeZip,
			mailingAddress: state.mailingAddress,
			mailingCity: state.mailingCity,
			mailingState: state.mailingState,
			mailingZip: state.mailingZip,
		});

		return resolve();
	});
}

function error(title: string, content: string) {
	return Modal.error({ title, content });
}
