import { persistStore, persistReducer } from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import ability from "../roles/ability";
import reducers from "./reducers";

const reducer = persistReducer(
	{
		key: "root",
		storage: storage,
	},
	reducers
);
const store: Object = configureStore({
	reducer,
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
});

store.subscribe(() => {
	if (store.getState().auth) {
		ability.refresh(store.getState().auth);
	}
});

const persist = persistStore(store);
export default () => {
	return { store, persist };
};
