import { firestore } from "../../firebase";
import type { LoanPageState } from "./types";
import delay from "../../util/delay";

export default (state: LoanPageState): Promise<any> => {
	return new Promise(async resolve => {
		await firestore.doc("applications/" + state.loanID).update({
			income: state.income,
			mortgage: state.mortgage,
			household: state.household,
			debtPayments: state.debtPayments,

			// payrollType: state.discount.v6 ? "DirectDeposit" : null,
			incomeProof: state.discounts.v7,
			autoPayment: state.discounts.v8,
			utilityBill: state.discounts.v9,
			// living: state.discounts.v11 ? 2 : 1,
			mortgageBill: state.discounts.v12,
			returning: state.discounts.v14,

			lastUpdate: "Loan Advisor",
		});
		await firestore.doc("admin/" + state.loanID).update({
			loan: state.vLoan ?? 0,
			discount: state.vDiscount,
			vReview: state.discounts.v5,
			vDeposit: state.discounts.v6,
			vIncome: state.discounts.v7,
			vCard: state.discounts.v8,
			vUtilityBill: state.discounts.v9,
			vEmployment: state.discounts.v10,
			vResidence: state.discounts.v11,
			vMortgageBill: state.discounts.v12,
			vReferral: state.discounts.v13,
			vPreviousCustomer: state.discounts.v14,
		});
		await delay(1000);
		return resolve();
	});
};
