import { Layout, Row, Col, Typography, Input, Button, Modal, Carousel } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { auth } from "../firebase";
import "../login.css";

import { login } from "../redux/actions/auth";
import { getStores } from "../redux/actions/stores";
import Logo from "../assets/logo@2x.png";

type Props = {
	login: Function,
	getStores: Function,
};

type State = {
	email: string,
	password: string,
};

class Login extends Component<Props, State> {
	state = {
		email: "",
		password: "",
	};

	loginUser = () => {
		auth.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then(({ user }) => {
				this.props.getStores();
				return this.props.login({ uid: user.uid, photoURL: user.photoURL, name: user.displayName, email: user.email });
			})
			.catch(e => {
				Modal.error({ title: "Something is wrong1111", content: e.message });
			});
	};

	enterPressed = (evt: any): void => {
		if (evt.key === "Enter") {
			evt.preventDefault();
			evt.stopPropagation();
			this.loginUser();
		}
	};

	render() {
		return (
			<Layout.Content>
				<Row>
					<Col md={12} xs={24} style={{ padding: 20, display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
						<img src={Logo} style={{ height: 88, position: "absolute", left: 10, top: 20 }} alt={"Discount Title Loans"} />
						<div style={{ textAlign: "center" }}>
							<Typography.Title level={2}>Good Day! Welcome Back</Typography.Title>
							<Typography.Title level={4} style={{ color: "#888", marginTop: -10, marginBottom: 20 }}>
								Enter your details here
							</Typography.Title>
							<div style={{ maxWidth: 320, margin: "auto", padding: 0 }}>
								<Input
									style={{ marginTop: 5, marginBottom: 5, height: 38 }}
									prefix={<i className="fal fa-user" />}
									placeholder="Email"
									onKeyDown={this.enterPressed}
									value={this.state.email}
									onChange={e => this.setState({ email: e.currentTarget.value })}
								/>
								<Input
									style={{ marginTop: 5, marginBottom: 5, height: 38 }}
									prefix={<i className="fal fa-lock" />}
									placeholder="Password"
									type={"password"}
									onKeyDown={this.enterPressed}
									value={this.state.password}
									onChange={e => this.setState({ password: e.currentTarget.value })}
								/>
								<br />
								<br />
								<Button htmlType="submit" onClick={() => this.loginUser()} type={"primary"} block>
									Login
								</Button>
							</div>
						</div>
						<span style={{ position: "absolute", bottom: 20 }}>
							Developed by{" "}
							<a href={"https://studiomediaagency.com"} target={"_blank"} rel="noopener noreferrer">
								Studio Media
							</a>
						</span>
					</Col>
					<Col md={12} xs={24} style={{ textAlign: "center", height: "100vh" }}>
						<Carousel autoplay={true} effect={"fade"}>
							<div className={"slide1"}>
								<div className={"content"}>
									<img src={require("../assets/1.png")} alt={"Onboarding"} />
									<h1>Applications</h1>
									<p>Access all active applications of your data in seconds</p>
								</div>
							</div>
							<div className={"slide2"}>
								<div className={"content"}>
									<img src={require("../assets/2.png")} alt={"Onboarding"} />
									<h1>Information</h1>
									<p>Get the data you need when you need it</p>
								</div>
							</div>
							<div className={"slide3"}>
								<div className={"content"}>
									<img src={require("../assets/3.png")} alt={"Onboarding"} />
									<h1>Loan</h1>
									<p>Customize the loan to provide the best offer</p>
								</div>
							</div>
							<div className={"slide4"}>
								<div className={"content"}>
									<img src={require("../assets/4.png")} alt={"Onboarding"} />
									<h1>Utilities</h1>
									<p>Access more utilities like Blackbook, U130 and more!</p>
								</div>
							</div>
						</Carousel>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		login: payload => dispatch(login(payload)),
		getStores: () => dispatch(getStores()),
	};
}

//$FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Login);
