import type { State, Props } from "./types";
import { Button, Table, Modal } from "antd";
import async from "async";
import moment from "moment";
import React from "react";
import axios from "axios";
import delay from "../../util/delay";

export default (props: { state: State, props: Props, onMatch: Function }) => {
	if (props.props.user.referral) {
		return <div />;
	}

	const stores = [];
	async.eachOfSeries(
		props.state.referrals,
		(referral, key, callback) => {
			const store = {
				text: referral.store,
				value: referral.store,
			};
			if (stores.filter((x: Object) => x.value === referral.store).length === 0) {
				stores.push(store);
			}
			callback();
		},
		() => {
			stores.sort((a, b) => a.value.localeCompare(b.value));
		}
	);

	return (
		<Table
			loading={props.state.loading}
			pagination={{ pageSize: 30 }}
			dataSource={props.state.referrals}
			rowKey={r => r.id}
			columns={[
				{
					title: "Date",
					dataIndex: "created",
					defaultSortOrder: "descend",
					sorter: (a, b) => moment(a.created).diff(moment(b.created)),
					render: date => moment(date).format("lll"),
				},
				{
					title: "Name",
					dataIndex: "name",
					sorter: (a, b) => a.name.localeCompare(b.name),
				},
				{
					title: "Phone",
					dataIndex: "phone",
					sorter: (a, b) => a.phone.localeCompare(b.phone),
				},
				{
					title: "Store",
					dataIndex: "store",
					filters: stores,
					onFilter: (value, record) => record.store === value,
					sorter: (a, b) => a.store.localeCompare(b.store),
				},
				{
					title: "Payee",
					dataIndex: "payee",
					sorter: (a, b) => a.payee.localeCompare(b.payee),
				},
				{
					title: "Actions",
					render: record => {
						return (
							<Button
								type={"primary"}
								onClick={() => {
									Modal.confirm({
										title: "Please Confirm",
										content: "This will match the application to an existing referral",
										okText: "Match",
										onOk: async () => {
											await axios(
												"https://us-central1-digital-loans.cloudfunctions.net/assignReferral?crm=true&referral=" +
													record.id +
													"&application=" +
													props.props.uid
											);
											await delay(1000);
											props.onMatch();
										},
									});
								}}>
								Match
							</Button>
						);
					},
				},
			]}
		/>
	);
};
