import React, { Component } from "react";
// import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { Form, Button, Layout, Typography } from "antd";
import Data from "./data";
import Details from "./details";
import Pictures from "./pictures";
import type { Props, State } from "./types";
import { firestore } from "../../firebase";

class Vehicle extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			u130Loading: false,
			recalculating: false,

			// Vehicle
			carPrice: 0,
			mileage: props.applications[0].mileage,
			plate: props.applications[0].plate,
			vin: props.applications[0].vin,
			uvc: props.applications[0].uvc,

			// Vehicle Information
			vMake: "",
			vModel: "",
			vModelYear: "",
			vSeries: "",

			// Verifications
			vMileage: props.admin.vMileage,
			vVin: props.admin.vVin,
			vPlate: props.admin.vPlate,

			// Details
			color: props.admin.color,
			weight: props.admin.weight,
			county: props.admin.county,
			ownerName: props.admin.ownerName,
			ownerCity: props.admin.ownerCity,
			ownerState: props.admin.ownerState,

			// Co Borrower
			coBorrower: props.admin.coBorrower,
			coBorrowerFirstName: props.admin.coBorrowerFirstName || "",
			coBorrowerLastName: props.admin.coBorrowerLastName || "",
			coBorrowerPhone: props.admin.coBorrowerPhone || "",
			coBorrowerDOB: props.admin.coBorrowerDOB || "",
			coBorrowerSSN: props.admin.coBorrowerSSN || "",

			coBorrowerAddress: props.admin.coBorrowerAddress || "",
			coBorrowerCity: props.admin.coBorrowerCity || "",
			coBorrowerState: props.admin.coBorrowerState || "",
			coBorrowerZip: props.admin.coBorrowerZip || "",
		};
	}

	async componentDidMount(): any {
		await this.getVehicleDataFromBlackBook();
	}

	getVehicleDataFromBlackBook = async () => {
		const check = this.state.vin ?? this.state.uvc ?? null;
		const checkType = this.state.vin ? "VIN" : this.state.uvc ? "UVC" : null;
		const mileage = this.state.mileage ?? 100000;
		if (check && checkType) {
			this.setState({ recalculating: true });
			const url = `https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/${checkType}/${check}?mileage=${mileage}&customerid=website`;
			const blackbook = await axios.get(url, {
				headers: {
					Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
				},
			});
			const vehicle: { adjusted_tradein_avg: number, make: string, model: string, series: string, model_year: string } =
				blackbook.data["used_vehicles"]["used_vehicle_list"][0];
			if (vehicle) {
				this.setState({
					carPrice: vehicle.adjusted_tradein_avg,
					vMake: vehicle.make,
					vModelYear: vehicle.model_year,
					vModel: vehicle.model,
					vSeries: vehicle.series,
				});
			}
			this.setState({
				recalculating: false,
			});
		}
	};

	save = async (): any => {
		this.setState({ loading: true });

		await firestore.doc("admin/" + this.props.uid).update({
			vMileage: this.state.vMileage ?? false,
			vVin: this.state.vVin ?? null,
			vPlate: this.state.vPlate ?? null,
			color: this.state.color ?? null,
			weight: this.state.weight ?? null,
			county: this.state.county ?? null,
			ownerName: this.state.ownerName ?? null,
			ownerCity: this.state.ownerCity ?? null,
			ownerState: this.state.ownerState ?? null,
			coBorrower: this.state.coBorrower ?? null,
			coBorrowerFirstName: this.state.coBorrowerFirstName ?? null,
			coBorrowerLastName: this.state.coBorrowerLastName ?? null,
			coBorrowerPhone: this.state.coBorrowerPhone ?? null,
			coBorrowerDOB: this.state.coBorrowerDOB ?? null,
			coBorrowerSSN: this.state.coBorrowerSSN ?? null,

			coBorrowerAddress: this.state.coBorrowerAddress ?? null,
			coBorrowerCity: this.state.coBorrowerCity ?? null,
			coBorrowerState: this.state.coBorrowerState ?? null,
			coBorrowerZip: this.state.coBorrowerZip ?? null,
		});
		await firestore.doc("applications/" + this.props.uid).update({
			carPrice: this.state.carPrice ?? null,
			mileage: this.state.mileage ?? null,
			plate: this.state.plate ?? null,
			vin: this.state.vin ?? null,
		});

		await this.setState({ loading: false });
		this.props.reload();
	};

	u130 = () => {
		this.setState({ u130Loading: true });
		return axios
			.get(`https://us-central1-digital-loans.cloudfunctions.net/u130/${this.props.uid}`, {
				headers: {
					Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c",
				},
			})
			.then(res => {
				this.setState({ u130Loading: false });
				return window.open(res.data, "_blank");
			});
	};

	render() {
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<Form onFinish={this.save}>
					<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
						<Typography.Title level={1}>Vehicle</Typography.Title>
						<Data onChange={e => this.setState({ [e.name]: e.value })} state={this.state} recalculate={this.getVehicleDataFromBlackBook} />
						<br />
						<br />
						<Details onChange={e => this.setState({ [e.name]: e.value })} state={this.state} />
						<Button htmlType={"submit"} type={"primary"} loading={this.state.loading}>
							Save
						</Button>
						<Button onClick={this.u130} type={"primary"} loading={this.state.u130Loading} style={{ marginLeft: 10 }}>
							Download U130
						</Button>
						<Pictures pictures={this.props.applications[0].vehicle} />
					</div>
				</Form>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

//$FlowFixMe
export default connect(mapStateToProps)(Vehicle);
