import { Checkbox, Col, Input, Row, Typography } from "antd";
import MaskedInput from "react-text-mask";
import type { State } from "./types";
import React from "react";

export default (props: { onChange: Function, state: State }) => {
	return (
		<>
			<Typography.Title level={3}>User</Typography.Title>
			<Row gutter={[20, 25]}>
				<Col span={6}>
					<b>First Name</b>
					<Input value={props.state.firstName} onChange={e => props.onChange({ name: "firstName", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Last Name</b>
					<Input value={props.state.lastName} onChange={e => props.onChange({ name: "lastName", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Phone</b>
					<Input value={props.state.phone} onChange={e => props.onChange({ name: "phone", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>SSN</b>
					<MaskedInput
						className={"ant-input"}
						mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
						value={props.state.ssn}
						onChange={e => props.onChange({ name: "ssn", value: e.currentTarget.value })}
					/>
				</Col>
				<Col span={6}>
					<b>Home Address</b>
					<Input value={props.state.homeAddress} onChange={e => props.onChange({ name: "homeAddress", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Home City</b>
					<Input value={props.state.homeCity} onChange={e => props.onChange({ name: "homeCity", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Home State</b>
					<Input
						readOnly
						disabled
						value={props.state.homeState}
						onChange={e => props.onChange({ name: "homeState", value: e.currentTarget.value })}
					/>
				</Col>
				<Col span={6}>
					<b>Home Zip Code</b>
					<MaskedInput
						className={"ant-input"}
						mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
						value={props.state.homeZip}
						onChange={e => props.onChange({ name: "homeZip", value: parseInt(e.currentTarget.value) })}
					/>
				</Col>
				<Col span={6}>
					<b>Mailing Address</b>
					<Input value={props.state.mailingAddress} onChange={e => props.onChange({ name: "mailingAddress", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Mailing City</b>
					<Input value={props.state.mailingCity} onChange={e => props.onChange({ name: "mailingCity", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					<b>Mailing State</b>
					<Input
						readOnly
						disabled
						value={props.state.mailingState}
						onChange={e => props.onChange({ name: "mailingState", value: e.currentTarget.value })}
					/>
				</Col>
				<Col span={6}>
					<b>Mailing Zip Code</b>
					<MaskedInput
						className={"ant-input"}
						mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
						value={props.state.mailingZip}
						onChange={e => props.onChange({ name: "mailingZip", value: parseInt(e.currentTarget.value) })}
					/>
				</Col>
			</Row>
			<Checkbox checked={props.state.vId} onChange={e => props.onChange({ name: "vId", value: e.target.checked })}>
				Verified ID
			</Checkbox>
			<Checkbox checked={props.state.vResidence} onChange={e => props.onChange({ name: "vResidence", value: e.target.checked })}>
				Verified Address
			</Checkbox>
		</>
	);
};
