import React from "react";

export default (props: { name: string, color?: string }) => {
	const style = {};
	if (props.color) {
		style.color = props.color;
	}
	return (
		<span className={"anticon"}>
			<span style={style} className={"fas fa-" + props.name} />
		</span>
	);
};
