export function checkEmail(firstName: string, lastName: string, email: string) {
	const firstNameClean = firstName.toLocaleLowerCase();
	const lastNameClean = lastName.toLocaleLowerCase();
	const emailClean = email.toLocaleLowerCase();
	return emailClean.includes(firstNameClean) || email.includes(lastNameClean);
}

export function checkUppercase(firstName: string, lastName: string, city: string) {
	return (
		firstName.charAt(0) === firstName.charAt(0).toUpperCase() &&
		lastName.charAt(0) === lastName.charAt(0).toUpperCase() &&
		city.charAt(0) === city.charAt(0).toUpperCase()
	);
}
