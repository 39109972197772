import React from "react";
import AppHeader from "../../components/appHeader";
import { Typography, Input, InputNumber, Button, Modal, Row, Col } from "antd";
import axios from "axios";
import Details from "./details";
import Vehicle from "./vehicle";

type Props = {};

type State = {
	odometer: number,
	vin: string,
	vinLoading: boolean,
	uvc: string,
	uvcLoading: boolean,
	vehicle?: Object,
};

class Index extends React.PureComponent<Props, State> {
	state = {
		odometer: 100000,
		vin: "",
		vinLoading: false,
		uvc: "",
		uvcLoading: false,
	};

	checkVIN = async () => {
		await this.setState({ vinLoading: true });
		const blackbook = await axios({
			method: "GET",
			url: "https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/VIN/" + this.state.vin,
			params: {
				mileage: this.state.odometer,
				customerid: "DTL-Digital-BlackbookPage",
			},
			headers: {
				Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
			},
		});
		const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
		if (vehicle) {
			this.setState({ vehicle });
		} else {
			Modal.error({ title: "Something is wrong", content: "Vehicle not found" });
		}
		this.setState({ vinLoading: false });
	};

	checkUVC = async () => {
		await this.setState({ uvcLoading: true });
		const blackbook = await axios({
			method: "GET",
			url: "https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/UsedVehicle/UVC/" + this.state.uvc,
			params: {
				mileage: this.state.odometer,
				customerid: "DTL-Digital-BlackbookPage",
			},
			headers: {
				Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
			},
		});
		const vehicle = blackbook.data["used_vehicles"]["used_vehicle_list"][0];
		if (vehicle) {
			this.setState({ vehicle });
		} else {
			Modal.error({ title: "Something is wrong", content: "Vehicle not found" });
		}
		await this.setState({ uvcLoading: false });
	};

	render() {
		return (
			<div>
				<AppHeader />
				<div style={{ padding: 30, paddingTop: 200 }}>
					<div className={"doNotPrint"}>
						<Typography.Title>Blackbook</Typography.Title>
						<Row>
							<Col xs={24} md={4}>
								Odometer
								<br />
								<InputNumber placeholder={"Mileage"} style={{ width: "100%" }} onChange={e => this.setState({ odometer: e })} />
							</Col>
						</Row>
						<br />
						<br />
						<Typography.Title level={4}>Find Vehicle using VIN</Typography.Title>
						<Row gutter={10}>
							<Col xs={12} md={4}>
								<Input placeholder={"Vin Number"} onChange={e => this.setState({ vin: e.currentTarget.value })} />
							</Col>
							<Col xs={12} md={4}>
								<Button type={"primary"} onClick={this.checkVIN} loading={this.state.vinLoading}>
									Check VIN
								</Button>
							</Col>
						</Row>
						<br />
						<br />
						<Typography.Title level={4}>Find Vehicle using UVC</Typography.Title>
						<Row gutter={10}>
							<Col xs={24} md={16}>
								<Vehicle onChange={uvc => this.setState({ uvc })} />
							</Col>
							<Col xs={24} md={6}>
								<br />
								<Button type={"primary"} onClick={this.checkUVC} loading={this.state.uvcLoading}>
									Check UVC
								</Button>
							</Col>
						</Row>
					</div>
					<br />
					<br />
					<Details state={this.state} />
				</div>
			</div>
		);
	}
}

export default Index;
