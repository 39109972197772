import React from "react";
import type { Props } from "./types";

function Files(props: Props) {
	const application = props.applications[0];
	const idImages = application.id;
	const addressImages = application.residence;
	return (
		<>
			<br />
			<br />
			<b>ID & Address Files</b>
			<br />
			{idImages ? idImages.map(image => Image(image, "ID")) : null}
			{addressImages ? addressImages.map(image => Image(image, "Address")) : null}
		</>
	);
}

function Image(url, type) {
	return (
		<a href={url} target={"_blank"} rel="noopener noreferrer" key={url}>
			<img src={url} width={120} height={120} style={{ margin: 5 }} alt={type} />
		</a>
	);
}

export default Files;
