import React from "react";
import { Button, Col, Input, notification, Row, Typography } from "antd";
import { connect } from "react-redux";
import { auth } from "../firebase";
import Footer from "../components/footer";
import AppHeader from "../components/appHeader";

type Props = {
	uid: string,
	email: string,
};
type State = {
	current: string,
	password: string,
	confirm: string,
	loading: boolean,
};

class Profile extends React.Component<Props, State> {
	state = {
		current: "",
		password: "",
		confirm: "",
		loading: false,
	};

	saveProfile = async () => {
		const { current, password, confirm } = this.state;
		this.setState({ loading: true });
		const user = await auth.currentUser;

		try {
			await auth.signInWithEmailAndPassword(this.props.email, current);
			if (password && confirm) {
				if (password !== confirm) {
					return notification.error({ message: "Ooops!", description: "Passwords do not match!" });
				}
				await user.updatePassword(password);
				return notification.success({ message: "Success!", description: "Password updated successfully!" });
			}
		} catch (e) {
			notification.error({ message: "Ooops!", description: e.message });
		}
		return null;
	};

	render() {
		return (
			<div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
				<AppHeader />
				<div style={{ paddingTop: 200, paddingLeft: 40, minHeight: "100vh" }}>
					<Typography.Title level={1}>Profile</Typography.Title>
					<Row style={{ paddingBottom: 20 }} gutter={[16, 16]}>
						<Col span={24}>
							<label style={{ fontSize: 20 }}>Current Password</label>
							<br />
							<Input
								type="password"
								value={this.state.current}
								onChange={e => this.setState({ current: e.currentTarget.value })}
								style={{ width: 300 }}
							/>
						</Col>
						<Col span={24}>
							<label style={{ fontSize: 20 }}>Password</label>
							<br />
							<Input
								type="password"
								value={this.state.password}
								onChange={e => this.setState({ password: e.currentTarget.value })}
								style={{ width: 300 }}
							/>
						</Col>
						<Col span={24}>
							<label style={{ fontSize: 20 }}>Confirm Password</label>
							<br />
							<Input
								type="password"
								value={this.state.confirm}
								onChange={e => this.setState({ confirm: e.currentTarget.value })}
								style={{ width: 300 }}
							/>
						</Col>
						<Col style={{ paddingTop: 22 }} span={4}>
							<Button onClick={this.saveProfile} type="primary">
								Update Password
							</Button>
						</Col>
					</Row>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	email: state.auth.email,
	uid: state.auth.uid,
});

//$FlowFixMe
export default connect(mapStateToProps)(Profile);
