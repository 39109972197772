import { combineReducers } from "@reduxjs/toolkit";

const authReducer = (state = null, action) => {
	switch (action.type) {
		case "LOGIN":
			return action.payload;
		case "LOGOUT":
			return null;
		case "UPDATE_PHOTO":
			return {
				...state,
				photoURL: action.payload,
			};
		default:
			return state;
	}
};

const filterReducer = (state = [], action) => {
	if (action.type === "SET_FILTER") {
		return action.payload;
	}
	return state;
};

const storesReducer = (state = [], action) => {
	if (action.type === "SET_STORES") {
		return action.payload;
	}
	if (action.type === "REMOVE_STORES") {
		return [];
	}
	return state;
};

export default combineReducers({
	auth: authReducer,
	stores: storesReducer,
	filter: filterReducer,
});
