import React from "react";
import { Typography } from "antd";

type Props = {
	data: Object | null,
};

function Data(props: Props) {
	if (!props.data) {
		return <div />;
	}
	return (
		<>
			<Typography.Title level={3}>Loan</Typography.Title>
			<table>
				<thead>
					<tr>
						<th>Month</th>
						<th>Payment</th>
						<th>Principal</th>
						<th>Fees & Interest</th>
					</tr>
				</thead>
				<tbody>
					{props.data.amortized.map(amortized => (
						<tr key={amortized.payment}>
							<td>{amortized.payment}</td>
							<td>${props.data ? props.data.payment.toFixed() : null}</td>
							<td>${amortized.principal.toFixed()}</td>
							<td>${amortized.interests.toFixed()}</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<th>Totals:</th>
						<th>${props.data ? props.data.totals.payments.toFixed() : null}</th>
						<th>${props.data ? props.data.loan.toFixed() : null}</th>
						<th>${props.data ? props.data.totals.interests.toFixed() : null}</th>
					</tr>
				</tfoot>
			</table>
		</>
	);
}

export default Data;
