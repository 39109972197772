import { Layout, Typography, Row, Col, InputNumber, Divider, Button } from "antd";
import type { LoanPageProps, LoanPageState } from "./types";
import React, { PureComponent } from "react";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import axios from "axios";

import Discounts from "./discounts";
import Data from "./data";
import "./style.css";
import save from "./save";
import delay from "../../util/delay";

class Loan extends PureComponent<LoanPageProps, LoanPageState> {
	constructor(props: LoanPageProps) {
		super(props);
		const loanID = props.match.params.loanID;
		const application = props.applications.find(e => e.key === loanID);
		this.state = {
			data: null,
			loading: false,
			recalculating: false,

			loanID: loanID,
			loanName: loanID.substr(loanID.length - 4).toUpperCase(),

			income: application ? application.income : 0,
			mortgage: application ? application.mortgage : 0,
			household: application ? application.household : 0,
			debtPayments: application ? application.debtPayments : 0,
			discount: application ? application.discount : 0,
			loan: application ? parseFloat(application.loan?.toFixed()) : 0,
			term: application ? application.term : "",

			vLoan: props.admin.loan,
			vDiscount: props.admin.discount,
			discounts: {},
		};
	}

	componentDidMount(): void {
		this.getData();
	}

	getData = () => {
		const loanID = this.props.match.params.loanID;
		return firestore
			.doc(`applications/${loanID}/data/sixMonths`)
			.get()
			.then(data => {
				return this.setState({ data: data.data() });
			});
	};

	recalculate = async () => {
		await this.setState({ recalculating: true });
		const remote: {
			data: {
				data6: Object,
			},
		} = await axios({
			method: "GET",
			url: "https://us-central1-digital-loans.cloudfunctions.net/app/calculateTitleLoan/" + this.state.loanID,
			params: {
				discount: this.state.vDiscount,
				loan: this.state.vLoan,
				income: this.state.income,
				debt: this.state.debtPayments,
				mortgage: this.state.mortgage,
				household: this.state.household,
			},
		});

		await this.setState({ data: remote.data.data6, recalculating: false });
	};

	discountsChanged = (e: Object) => {
		this.setState({ vDiscount: e.totalDiscounts, discounts: e });
	};

	save = async () => {
		await this.setState({ loading: true });
		return save(this.state).then(async () => {
			await delay(1500);
			await this.setState({ loading: false });
			this.props.reload();
			return this.getData();
		});
	};

	render() {
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }} className={"loan"}>
					<Typography.Title level={1}>Application {this.state.loanName}</Typography.Title>
					<Typography.Title level={3}>Data</Typography.Title>
					<Row gutter={[20, 25]}>
						<Col xs={12} lg={6}>
							Monthly Income
							<InputNumber style={{ width: "100%" }} type={"number"} value={this.state.income} onChange={e => this.setState({ income: e })} />
						</Col>
						<Col xs={12} lg={6}>
							Rent or Mortgage Amount
							<InputNumber style={{ width: "100%" }} type={"number"} value={this.state.mortgage} onChange={e => this.setState({ mortgage: e })} />
						</Col>
						<Col xs={12} lg={6}>
							People living in household
							<InputNumber
								style={{ width: "100%" }}
								type={"number"}
								value={this.state.household}
								onChange={e => this.setState({ household: e })}
							/>
						</Col>
						<Col xs={12} lg={6}>
							Monthly Debt
							<InputNumber
								style={{ width: "100%" }}
								type={"number"}
								value={this.state.debtPayments}
								onChange={e => this.setState({ debtPayments: e })}
							/>
						</Col>

						<Col xs={12} lg={5}>
							Self Reported Discounts
							<InputNumber style={{ width: "100%" }} type={"number"} value={this.state.discount} disabled />
						</Col>
						<Col xs={12} lg={5}>
							Self Reported Credit Limit
							<InputNumber style={{ width: "100%" }} type={"number"} value={this.state.loan} disabled />
						</Col>

						<Col xs={12} lg={5}>
							Verified Adjusted Discounts
							<InputNumber
								style={{ width: "100%" }}
								type={"number"}
								value={this.state.vDiscount}
								onChange={e => this.setState({ vDiscount: e })}
							/>
						</Col>
						<Col xs={12} lg={5}>
							Verify Adjusted Credit Limit
							<InputNumber style={{ width: "100%" }} type={"number"} value={this.state.vLoan} onChange={e => this.setState({ vLoan: e })} />
						</Col>
						<Col xs={12} lg={4}>
							<br />
							<Button type={"primary"} block loading={this.state.recalculating} onClick={this.recalculate}>
								Recalculate
							</Button>
						</Col>
					</Row>
					<Divider />
					<Row gutter={[20, 25]}>
						<Col xs={24} lg={12}>
							<Typography.Title level={3}>Discounts</Typography.Title>
							<Discounts
								application={this.props.applications.find(x => x.key === this.state.loanID)}
								user={this.props.user}
								admin={this.props.admin}
								onChange={this.discountsChanged}
							/>
						</Col>
						<Col xs={24} lg={12}>
							<Data data={this.state.data} />
						</Col>
					</Row>
					<br />
					<Button type={"primary"} loading={this.state.loading} onClick={this.save}>
						Save
					</Button>
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

//$FlowFixMe
export default connect(mapStateToProps)(Loan);
