import React from "react";
import { Col, Row, Typography, Statistic, Card, Button } from "antd";

function Details(props: {
	state: {
		odometer: number,
		vehicle?: {
			vin: string,
			model_year: string,
			make: string,
			model: string,
			series: string,

			adjusted_retail_clean: number,
			adjusted_whole_clean: number,
			adjusted_tradein_clean: number,

			adjusted_retail_avg: number,
			adjusted_whole_avg: number,
			adjusted_tradein_avg: number,

			adjusted_retail_rough: number,
			adjusted_whole_rough: number,
			adjusted_tradein_rough: number,
		},
	},
}) {
	if (!props.state.vehicle) {
		return <div />;
	}
	return (
		<>
			<Typography.Title level={4}>Vehicle Details</Typography.Title>
			<Row>
				<Col span={4}>
					<Statistic title="VIN" value={props.state.vehicle.vin} />
				</Col>
				<Col span={4}>
					<Statistic title="Mileage" value={props.state.odometer} />
				</Col>
				<Col span={4}>
					<Statistic title="Year" value={props.state.vehicle.model_year} groupSeparator={""} />
				</Col>
				<Col span={4}>
					<Statistic title="Make" value={props.state.vehicle.make} />
				</Col>
				<Col span={4}>
					<Statistic title="Model" value={props.state.vehicle.model} />
				</Col>
				<Col span={4}>
					<Statistic title="Series" value={props.state.vehicle.series} />
				</Col>
			</Row>
			<br />
			<br />
			<Row>
				<Col span={8}>
					<Card title="Clean Price">
						<Row>
							<Col span={8}>
								<Statistic title="Retail" prefix={"$"} value={props.state.vehicle.adjusted_retail_clean} />
							</Col>
							<Col span={8}>
								<Statistic title="Wholesale" prefix={"$"} value={props.state.vehicle.adjusted_whole_clean} />
							</Col>
							<Col span={8}>
								<Statistic title="Trade In" prefix={"$"} value={props.state.vehicle.adjusted_tradein_clean} />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={8}>
					<Card title="Average Price">
						<Row>
							<Col span={8}>
								<Statistic title="Retail" prefix={"$"} value={props.state.vehicle.adjusted_retail_avg} />
							</Col>
							<Col span={8}>
								<Statistic title="Wholesale" prefix={"$"} value={props.state.vehicle.adjusted_whole_avg} />
							</Col>
							<Col span={8}>
								<Statistic title="Trade In" prefix={"$"} value={props.state.vehicle.adjusted_tradein_avg} />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={8}>
					<Card title="Rough Price">
						<Row>
							<Col span={8}>
								<Statistic title="Retail" prefix={"$"} value={props.state.vehicle.adjusted_retail_rough} />
							</Col>
							<Col span={8}>
								<Statistic title="Wholesale" prefix={"$"} value={props.state.vehicle.adjusted_whole_rough} />
							</Col>
							<Col span={8}>
								<Statistic title="Trade In" prefix={"$"} value={props.state.vehicle.adjusted_tradein_rough} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<br />
			<br />
			<Button className={"doNotPrint"} type={"primary"} onClick={() => window.print()}>
				Print
			</Button>
		</>
	);
}

export default Details;
