import React from "react";
import { Button, Col, Popconfirm, Row } from "antd";
import moment from "moment";

export const columns = (manipulateData: Function, getColumnSearchProps: Function, stores: any) => [
	{
		title: "Date",
		dataIndex: "date",
		render: (record: string) => <p>{moment(record).format("ll")}</p>,
		// $FlowFixMe
		sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
	},
	{
		title: "Check No.",
		dataIndex: "check",
		...getColumnSearchProps("check"),
	},
	{
		title: "Store",
		dataIndex: "store_id",
		render: (store_id: any) => {
			const s = stores.find(x => x.prismID === store_id);
			return s?.name ?? store_id;
		},
	},
	{
		title: "Payee",
		dataIndex: "payee_name",
	},
	{
		title: "Purpose",
		dataIndex: "purpose_id",
		filters: [
			{
				text: "A/C Maintenance",
				value: 1,
			},
			{
				text: "B2B Check",
				value: 2,
			},
			{
				text: "C2B Check",
				value: 3,
			},
			{
				text: "Car Wash",
				value: 4,
			},
			{
				text: "Landscaping",
				value: 5,
			},
			{
				text: "Other (see notes)",
				value: 6,
			},
			{
				text: "Petty Cash Reimbursement",
				value: 7,
			},
			{
				text: "Repo Company",
				value: 8,
			},
			{
				text: "Signage",
				value: 9,
			},
			{
				text: "Store Replacement/Maintenance",
				value: 10,
			},
			{
				text: "VOIDED Check",
				value: 11,
			},
			{
				text: "Window Cleaning",
				value: 12,
			},
		],
		onFilter: (value: any, record: any) => record.purpose_id === value,
		render: (record: number) => {
			switch (record) {
				case 1:
					return "A/C Maintenance";
				case 2:
					return "B2B Check";
				case 3:
					return "C2B Check";
				case 4:
					return "Car Wash";
				case 5:
					return "Landscaping";
				case 6:
					return "Other (see notes)";
				case 7:
					return "Petty Cash Reimbursement";
				case 8:
					return "Repo Company";
				case 9:
					return "Signage";
				case 10:
					return "Store Replacement/Maintenance";
				case 11:
					return "VOIDED Check";
				case 12:
					return "Window Cleaning";
				default:
					return "";
			}
		},
	},
	{
		title: "Amount",
		dataIndex: "amount",
		render: (record: number) => "$" + record,
	},
	{
		title: "Notes",
		dataIndex: "notes",
		...getColumnSearchProps("notes"),
	},
	{
		title: "Actions",
		key: "actions",
		width: 200,
		render: (record: Object) => (
			<Row>
				<Col span={8}>
					<Button type={"link"} style={{ padding: 0 }} onClick={() => manipulateData(record, "edit")}>
						Edit
					</Button>
				</Col>
				<Col span={8}>
					<Popconfirm title={"Are you sure you want to delete this log?"} okText={"Yes"} onConfirm={() => manipulateData(record.id, "delete")}>
						<Button type={"link"} style={{ padding: 0 }}>
							Delete
						</Button>
					</Popconfirm>
				</Col>
			</Row>
		),
	},
];
