import React from "react";
import { Button, Col, Popconfirm, Row } from "antd";
import moment from "moment";

export const columns = (manipulateData: Function) => [
	{
		title: "Date",
		dataIndex: "date",
		dateRange: true,
		render: (record: any) => <p>{moment(record).format("ll")}</p>,
		sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
	},
	{
		title: "Store",
		dataIndex: "store_name",
		filters: [
			{
				value: "#01 MCALLEN",
				text: "#01 MCALLEN",
			},
			{
				value: "#02 WESLACO",
				text: "#02 WESLACO",
			},
			{
				value: "#03 HARLINGEN",
				text: "#03 HARLINGEN",
			},
			{
				value: "#04 PHARR",
				text: "#04 PHARR",
			},
			{
				value: "#05 EDINBURG",
				text: "#05 EDINBURG",
			},
			{
				value: "#06 MISSION",
				text: "#06 MISSION",
			},
			{
				value: "#07 BROWNSVILLE",
				text: "#07 BROWNSVILLE",
			},
			{
				value: "#08 ALAMO",
				text: "#08 ALAMO",
			},
			{
				value: "#09 LAREDO",
				text: "#09 LAREDO",
			},
			{
				value: "#10 MCALLEN",
				text: "#10 MCALLEN",
			},
			{
				value: "#11 CORPUS CHRISTI",
				text: "#11 CORPUS CHRISTI",
			},
			{
				value: "#12 LAREDO",
				text: "#12 LAREDO",
			},
			{
				value: "#13 CORPUS CHRISTI",
				text: "#13 CORPUS CHRISTI",
			},
			{
				value: "#14 MCALLEN",
				text: "#14 MCALLEN",
			},
			{
				value: "#15 RIO GRANDE CITY",
				text: "#15 RIO GRANDE CITY",
			},
			{
				value: "#16 SA DIGITAL",
				text: "#16 SA DIGITAL",
			},
			{
				value: "#17 EAGLE PASS",
				text: "#17 EAGLE PASS",
			},
			{
				value: "#18 BEEVILLE",
				text: "#18 BEEVILLE",
			},
			{
				value: "#19 SA SOUTHWEST",
				text: "#19 SA SOUTHWEST",
			},
			{
				value: "#20 SA EAST",
				text: "#20 SA EAST",
			},
			{
				value: "#99 CALL CENTER",
				text: "#99 CALL CENTER",
			},
			{
				value: "#21 HOUSTON NORTH",
				text: "#21 HOUSTON NORTH",
			},
			{
				value: "#22 HOUSTON NORTHEAST",
				text: "#22 HOUSTON NORTHEAST",
			},
			{
				value: "#24 HOUSTON NORTHWEST",
				text: "#24 HOUSTON NORTHWEST",
			},
			{
				value: "#21 HOUSTON NORTH",
				text: "#21 HOUSTON NORTH",
			},
			{
				value: "#28 HOUSTON SOUTHWEST",
				text: "#28 HOUSTON SOUTHWEST",
			},
			{
				value: "#25 HOUSTON SOUTH",
				text: "#25 HOUSTON SOUTH",
			},
			{
				value: "#21 HOUSTON PASADENA",
				text: "#21 HOUSTON PASADENA",
			},
		],
		onFilter: (value: any, record: any) => record["store_name"] === value,
	},
	{
		title: "CAB Fees",
		dataIndex: "cab_fees",
		search: "cab_fees",
		render: (record: any) => `$${record}`,
	},
	// {
	// 	title: "Payee",
	// 	dataIndex: "payee_id",
	// },
	{
		title: "CD",
		dataIndex: "value_new_loans",
		search: "value_new_loans",
		render: (record: any) => `$${record}`,
	},
	{
		title: "Loan Balance",
		dataIndex: "loan_balance",
		search: "loan_balance",
		render: (record: any) => `$${record}`,
	},
	{
		title: "Write-Offs",
		dataIndex: "due_write_off",
		search: "due_write_off",
		render: (record: any) => `$${record}`,
	},
	{
		title: "Actions",
		key: "actions",
		width: 200,
		render: (record: Object) => (
			<Row>
				<Col span={8}>
					<Button type={"link"} style={{ padding: 0 }} onClick={() => manipulateData(record, "edit")}>
						Edit
					</Button>
				</Col>
				<Col span={8}>
					<Popconfirm title={"Are you sure you want to delete this log?"} okText={"Yes"} onConfirm={() => manipulateData(record, "delete")}>
						<Button type={"link"} style={{ padding: 0 }}>
							Delete
						</Button>
					</Popconfirm>
				</Col>
			</Row>
		),
	},
];
