import React from "react";
import { Button, Checkbox, Col, Input, Row } from "antd";
import type { State } from "./types";

export default (props: { onChange: Function, recalculate: Function, state: State }) => {
	return (
		<>
			<Row style={{ paddingBottom: 20 }} gutter={10}>
				<Col span={5}>
					Adjusted Trade-In Value
					<Input
						type={"number"}
						value={props.state.carPrice}
						onChange={e => props.onChange({ name: "carPrice", value: parseFloat(e.currentTarget.value) })}
					/>
				</Col>
				<Col span={5}>
					Odometer
					<Input
						type={"number"}
						value={props.state.mileage}
						onChange={e => props.onChange({ name: "mileage", value: parseFloat(e.currentTarget.value) })}
					/>
				</Col>
				<Col span={5}>
					License Plate
					<Input value={props.state.plate} onChange={e => props.onChange({ name: "plate", value: e.currentTarget.value })} />
				</Col>
				<Col span={5}>
					Vin Number
					<Input value={props.state.vin} onChange={e => props.onChange({ name: "vin", value: e.currentTarget.value })} />
				</Col>
				<Col style={{ paddingTop: 22 }} span={4}>
					<Button onClick={props.recalculate} loading={props.state.recalculating} type="primary" style={{ width: "100%" }}>
						Recalculate
					</Button>
				</Col>
			</Row>
			<Checkbox checked={props.state.vMileage} onChange={e => props.onChange({ name: "vMileage", value: e.target.checked })}>
				Verified Odometer
			</Checkbox>
			<Checkbox checked={props.state.vVin} onChange={e => props.onChange({ name: "vVin", value: e.target.checked })}>
				Verified Vin
			</Checkbox>
			<Checkbox checked={props.state.vPlate} onChange={e => props.onChange({ name: "vPlate", value: e.target.checked })}>
				License Plate NO.
			</Checkbox>
		</>
	);
};
