import React, { PureComponent } from "react";
import { Col, Row, Select, Table, Typography, Button, Space, Input } from "antd";
import { connect } from "react-redux";
import { getApplications } from "./utils";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import type { Props, State } from "./types";
import getStepsAndCities from "./getStepsAndCities";
import columns from "./columns";
import async from "async";
import Can from "../../roles/can";
import Footer from "../../components/footer";
import AppHeader from "../../components/appHeader";
import { userFilter } from "../../redux/actions/filters";
import csv from "../../util/csv";
import moment from "moment";

class All extends PureComponent<Props, State> {
	state = {
		users: [],
		csv: [],
		exporting: false,
		cities: [],
		steps: [],
		total: 0,
		status: "Open",
		loading: true,
		searchText: "",
		searchedColumn: "",
	};

	componentDidMount(): any {
		return getApplications(this.state.status).then(data => {
			return this.setState({ users: data, csv: data, total: data.length, loading: false }, this.getExtra);
		});
	}

	getExtra = async () => {
		const { cities, steps } = await getStepsAndCities(this.state.users);
		if (this.props.filter?.length) {
			const filteredTotal = this.state.users.filter(x => {
				return this.props.filter.includes(x.store);
			}).length;
			this.setState({ total: filteredTotal });
		}
		this.setState({ cities, steps });
	};

	onChange = (pagination: number, filters: any, sorter: any, extra: Object) => {
		this.props.userFilter(filters.store);
		this.setState({
			total: extra.currentDataSource.length,
			csv: extra.currentDataSource,
		});
	};

	onSelectChange = (e: string): any => {
		this.setState({ status: e, loading: true }, () => {
			return getApplications(this.state.status).then(data => {
				return this.setState({ users: data, csv: data, total: data.length, loading: false }, this.getExtra);
			});
		});
	};

	getColumnSearchProps = (dataIndex: any) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						// $FlowFixMe
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
		onFilter: (value: any, record: any) =>
			record[dataIndex]
				?.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible: any) => {
			if (visible) {
				//$FlowFixMe
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text: string) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text?.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = (clearFilters: any) => {
		clearFilters();
		this.setState({ searchText: "" });
	};

	export = async () => {
		await this.setState({ exporting: true });
		const csvData = [];
		await async.eachOfSeries(this.state.csv, (file, key, callback) => {
			csvData.push({
				date: moment(file.date, "X").format("lll"),
				source: file.source,
				store: file.store,
				customerName: file.name,
				city: file.city,
				step: file.step,
				creditLimit: file.adminLoan > 0 ? file.adminLoan.toFixed() : file.applicationLoan > 0 ? file.applicationLoan.toFixed() : "NOT APPROVED",
				notes: file.notes ?? "",
				updated: moment(file.updated, "X").format("lll"),
			});
			callback();
		});
		await csv(csvData);
		await this.setState({ exporting: false });
	};

	render() {
		return (
			<div style={{ minHeight: "100vh" }}>
				<AppHeader />
				<div style={{ padding: 30, paddingTop: 200, minHeight: "100vh" }}>
					<Row>
						<Col span={12}>
							<Typography.Title>All Applications ({this.state.total})</Typography.Title>
						</Col>
						<Col style={{ textAlign: "right" }} span={12}>
							<Select defaultValue={this.state.status} onChange={this.onSelectChange}>
								<Select.Option value="Open">Open Applications</Select.Option>
								<Select.Option value="Closed">Closed Applications</Select.Option>
							</Select>
							<Can I={"export"} a={"csv"}>
								<Button loading={this.state.exporting} onClick={this.export} type={"primary"} style={{ marginLeft: 10 }}>
									Export to Excel
								</Button>
							</Can>
						</Col>
					</Row>
					<Table
						dataSource={this.state.users}
						loading={this.state.loading}
						pagination={{ pageSize: 50 }}
						rowKey={record => record.uid}
						columns={columns(this.state.cities, this.state.steps, this.getColumnSearchProps)}
						onChange={this.onChange}
					/>
				</div>
				<Footer />
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		stores: state.stores.sort((a, b) => a.name.localeCompare(b.name)),
		filter: state.filter,
	};
};
const dispatchToProps = (dispatch: any) => {
	return {
		userFilter: data => dispatch(userFilter(data)),
	};
};
//$FlowFixMe
export default connect(mapStateToProps, dispatchToProps)(All);
