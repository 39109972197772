import React, { useRef } from "react";
import { Avatar, Col, Layout, Menu, Row, Button, notification } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logo@2x.png";
import { logout, updateUserPhoto } from "../redux/actions/auth";
import { auth, storage } from "../firebase";
import Can from "../roles/can";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

export default function(props: any) {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const photoURL = useSelector(state => state.auth.photoURL);

	const uploadUserProfileImg = (image: Object) => {
		const user = auth.currentUser;
		const uploadTask = storage.ref(`adminUsers/${image.name}`).put(image);
		uploadTask.on(
			"state_changed",
			null,
			error => {
				notification.error({ message: "Unable to upload image", description: error.message });
			},
			async () => {
				const url = await storage
					.ref("adminUsers")
					.child(image.name)
					.getDownloadURL();

				await user.updateProfile({
					photoURL: url,
				});
				return dispatch(updateUserPhoto(url));
			}
		);
	};

	const path = window.location.pathname;
	const DropdownComponent = props => (
		<Menu.SubMenu
			icon={<i className={(path === "/c2b-referrals" ? "fas" : "fal") + " fa-user"} style={{ paddingRight: 10, fontSize: 18, color: "#fff" }} />}
			style={{
				color: "#fff",
				fontSize: 18,
			}}
			title={"Referrals"}
			{...props}>
			<Menu.Item>
				<Link style={{ fontSize: 18, color: "#fff" }} to="/b2b-referrals">
					<i className={(path === "/b2b-referrals" ? "fas" : "fal") + " fa-user"} style={{ paddingRight: 10, fontSize: 18 }} />
					B2B Referrals
				</Link>
			</Menu.Item>
			<Menu.Item>
				<Link style={{ fontSize: 18, color: "#fff" }} to="/c2b-referrals">
					<i className={(path === "/c2b-referrals" ? "fas" : "fal") + " fa-user"} style={{ paddingRight: 10, fontSize: 18 }} />
					C2B Referrals
				</Link>
			</Menu.Item>
			<Menu.Item>
				<Link style={{ fontSize: 18, color: "#fff" }} to="/send-referrals">
					<i className={(path === "/send-referrals" ? "fas" : "fal") + " fa-user"} style={{ paddingRight: 10, fontSize: 18 }} />
					Send DF Referrals
				</Link>
			</Menu.Item>
		</Menu.SubMenu>
	);
	return (
		<>
			<Layout.Header
				style={{
					height: 100,
					position: "fixed",
					zIndex: 1060,
					width: "100%",
					background: "#fff",
					paddingLeft: 30,
					paddingTop: 15,
					paddingRight: 30,
				}}>
				<Row>
					<Col span={12}>
						<img alt="logo" style={{ height: 80 }} src={logo} />
					</Col>
					<Col span={12} style={{ textAlign: "right" }}>
						<div
							style={{
								fontSize: 15,
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}>
							<span style={{ paddingRight: 10 }}>
								<Link to="/profile">MY PROFILE</Link>
							</span>
							<span style={{ fontSize: 30, fontWeight: "lighter", color: "000" }}>|</span>
							<Button onClick={() => dispatch(logout())} type={"link"} style={{ paddingLeft: 10, paddingRight: 20 }}>
								LOG OUT
							</Button>
							<Avatar onClick={() => ref.current?.click()} style={{ cursor: "pointer" }} size={50} src={photoURL} icon={<UserOutlined />} />
							<input
								type="file"
								name="file"
								id="file"
								ref={ref}
								style={{
									width: "0.1px",
									height: "0.1px",
									opacity: 0,
									overflow: "hidden",
									position: "absolute",
									zIndex: 1,
								}}
								onChange={e => {
									if (e.target.files[0]) {
										const image = e.currentTarget.files[0];
										uploadUserProfileImg(image);
									}
								}}
							/>
						</div>
					</Col>
				</Row>
			</Layout.Header>
			<Menu
				className={"doNotPrint"}
				mode="horizontal"
				overflowedIndicator={<MenuOutlined />}
				style={{
					paddingTop: 10,
					background: "#0985E6",
					width: "100%",
					paddingBottom: 10,
					paddingRight: 80,
					position: "fixed",
					zIndex: 1060,
					padding: 10,
					paddingLeft: 40,
					marginTop: 100,
				}}>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/">
						<i className={(path === "/" ? "fas" : "fal") + " fa-file-alt"} style={{ paddingRight: 10, fontSize: 18 }} />
						Applications
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/new">
						<i className={(path === "/new" ? "fas" : "fal") + " fa-file-plus"} style={{ paddingRight: 10, fontSize: 18 }} />
						New Application
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/payees">
						<i className={(path === "/payees" ? "fas" : "fal") + " fa-user"} style={{ paddingRight: 10, fontSize: 18 }} />
						Payees
					</Link>
				</Menu.Item>
				<DropdownComponent {...props} />
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/alerts">
						<i className={(path === "/alerts" ? "fas" : "fal") + " fa-bell"} style={{ paddingRight: 10, fontSize: 18 }} />
						Alerts
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/u130">
						<i className={(path === "/u130" ? "fas" : "fal") + " fa-file-signature"} style={{ paddingRight: 10, fontSize: 18 }} />
						U130
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/blackbook">
						<i className={(path === "/blackbook" ? "fas" : "fal") + " fa-car-alt"} style={{ paddingRight: 10, fontSize: 18 }} />
						Blackbook
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/tracker">
						<i className={(path === "/tracker" ? "fas" : "fal") + " fa-analytics "} style={{ paddingRight: 10, fontSize: 18 }} />
						Daily Tracker
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link style={{ fontSize: 18, color: "#fff" }} to="/check-logs">
						<i className={(path === "/check-logs" ? "fas" : "fal") + " fa-money-check-alt"} style={{ paddingRight: 10, fontSize: 18 }} />
						Check Logs
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Can I="view" a="users">
						<Link style={{ fontSize: 18, color: "#fff" }} to="/users">
							<i className={(path === "/users" ? "fas" : "fal") + " fa-users"} style={{ paddingRight: 10, fontSize: 18 }} />
							Admin Users
						</Link>
					</Can>
				</Menu.Item>
			</Menu>
		</>
	);
}
