import React, { useEffect, useState } from "react";
import AppHeader from "../../components/appHeader";
import { Col, Row, Table, Typography } from "antd";
import Footer from "../../components/footer";
import { firestore } from "../../firebase";
import { columns } from "./columns";
import actions from "./actions";

// $FlowFixMe
const C2BReferrals = () => {
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [bonus, setBonus] = useState(0);

	const getData = () => {
		firestore
			.collection("title-referrals")
			.get()
			.then(value => {
				setData(
					value.docs.map(item => {
						return {
							...item.data(),
							ref: item.ref,
							id: item.id,
							source: "C2B",
							date: item.data().timestamp.toDate(),
						};
					})
				);
				return setPageLoading(false);
			})
			.catch();
	};

	useEffect(getData, []);

	return (
		<div style={{ minHeight: "100vh" }}>
			<AppHeader />
			<div style={{ padding: 30, paddingTop: 200, minHeight: "100vh" }}>
				<Row>
					<Col span={12}>
						<Typography.Title>C2B Referrals</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12} />
				</Row>
				<Table
					dataSource={data}
					loading={pageLoading}
					pagination={{ pageSize: 50 }}
					columns={[...columns(visible, setVisible, bonus, setBonus, getData), ...actions(visible, setVisible, bonus, setBonus, getData)]}
					rowKey={key => key.id}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default C2BReferrals;
