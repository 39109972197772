import React, { PureComponent } from "react";
import type { Application } from "../../types/application";
import discounts from "../../data/discounts";
import { Checkbox } from "antd";
import { checkEmail, checkUppercase } from "../../util/checker";
import async from "async";

type Props = {
	application: Application | any,
	admin: Object,
	user: Object,
	onChange: Function,
};

type State = {
	loan: number,
	totalDiscounts: number,
	totalAmount: number,
	d1: boolean,
	v1?: boolean,
	d2: boolean,
	v2?: boolean,
	d3: boolean,
	v3?: boolean,
	d4: boolean,
	v4?: boolean,
	d5: boolean,
	v5?: boolean,
	d6: boolean,
	v6?: boolean,
	d7: boolean,
	v7?: boolean,
	d8: boolean,
	v8?: boolean,
	d9: boolean,
	v9?: boolean,
	d10: boolean,
	v10?: boolean,
	d11: boolean,
	v11?: boolean,
	d12: boolean,
	v12?: boolean,
	d13: boolean,
	v13?: boolean,
	d14: boolean,
	v14?: boolean,
};

class Discounts extends PureComponent<Props, State> {
	state = {
		loan: 0,
		totalDiscounts: 0,
		totalAmount: 0,
		d1: false,
		d2: false,
		d3: false,
		d4: false,
		d5: false,
		d6: false,
		d7: false,
		d8: false,
		d9: false,
		d10: false,
		d11: false,
		d12: false,
		d13: false,
		d14: false,
	};

	componentDidMount(): void {
		this.setState({
			loan: this.props.admin.loan ? this.props.admin.loan : this.props.application.loan,
			d1: checkUppercase(this.props.user.firstName, this.props.user.lastName, this.props.application.homeCity),
			d2: checkEmail(this.props.user.firstName, this.props.user.lastName, this.props.user.email),
			d3: Boolean(this.props.application.appleDevice),
			d4: Boolean(this.props.application.loan >= 500),
			d5: Boolean(this.props.admin.vReview),
			d6: Boolean(this.props.application.payrollType === "DirectDeposit"),
			d7: Boolean(this.props.application.incomeProof),
			d8: Boolean(this.props.application.autoPayment),
			d9: Boolean(this.props.application.utilityBill),
			d10: Boolean(this.props.application.hired),
			d11: Boolean(this.props.application.living === 2),
			d12: Boolean(this.props.application.mortgageBill),
			d13: Boolean(this.props.admin.vReferral),
			d14: Boolean(this.props.application.returning),
			//
			v5: Boolean(this.props.admin.vReview),
			v6: Boolean(this.props.admin.vDeposit),
			v7: Boolean(this.props.admin.vIncome),
			v8: Boolean(this.props.admin.vCard),
			v9: Boolean(this.props.admin.vUtilityBill),
			v10: Boolean(this.props.admin.vEmployment),
			v11: Boolean(this.props.admin.vResidence),
			v12: Boolean(this.props.admin.vMortgageBill),
			v13: Boolean(this.props.admin.vReferral),
			v14: Boolean(this.props.admin.vPreviousCustomer),
		});
	}

	componentDidUpdate = async () => {
		let totalDiscounts = 0;
		let totalAmount = 0;

		await async.eachOfSeries(
			discounts,
			(discount, index, callback) => {
				if (discount.verify && this.state["d" + (index + 1)] && this.state["v" + (index + 1)]) {
					totalDiscounts += discount.value;
					totalAmount += this.state.loan * (discount.value / 100);
				} else if (!discount.verify && this.state["d" + (index + 1)]) {
					totalDiscounts += discount.value;
					totalAmount += this.state.loan * (discount.value / 100);
				}
				return callback();
			},
			async () => {
				await this.setState({ totalAmount, totalDiscounts });
				this.props.onChange(this.state);
			}
		);
	};

	render() {
		if (!this.props.admin) {
			return null;
		}
		return (
			<table>
				<thead>
					<tr>
						<th style={{ textAlign: "left" }}>Discount</th>
						<th>Verify</th>
						<th style={{ width: 30 }}>Percentage</th>
						<th style={{ width: 50 }}>Amount</th>
					</tr>
				</thead>
				<tbody>
					{discounts.map((discount, index) => {
						if (index < 3) return <div />;
						return (
							<tr key={index}>
								<td style={{ textAlign: "left" }}>
									<Checkbox
										disabled={!discount.verify}
										checked={this.state["d" + (index + 1)]}
										onChange={e => {
											this.setState({
												["d" + (index + 1)]: e.target.checked,
											});
											if (!e.target.checked) {
												this.setState({
													["v" + (index + 1)]: false,
												});
											}
										}}>
										{discount.name}
									</Checkbox>
								</td>
								<td>
									{this.state["d" + (index + 1)] && discount.verify ? (
										<Checkbox
											checked={this.state["v" + (index + 1)]}
											onChange={e => this.setState({ ["v" + (index + 1)]: e.target.checked })}
										/>
									) : null}
								</td>
								<td>{discount.value ? discount.value + "%" : null}</td>
								<td>{discount.value ? "$" + (this.state.loan * (discount.value / 100)).toFixed() : null}</td>
							</tr>
						);
					})}
				</tbody>
				<tfoot>
					<tr>
						<th />
						<th />
						<th>{this.state.totalDiscounts.toFixed(1)}%</th>
						<th>${this.state.totalAmount.toFixed(1)}</th>
					</tr>
				</tfoot>
			</table>
		);
	}
}

export default Discounts;
