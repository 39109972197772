import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Typography, Button, Modal } from "antd";
import axios from "axios";
import Data from "./data";
import Referrals from "./referrals";
import type { Props, State } from "./types";
import { firestore } from "../../firebase";

class Referrer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: true,
			referrals: [],
		};
	}

	async componentDidMount(): any {
		await this.getData();
	}

	getData = async () => {
		await this.setState({ loading: true });
		if (this.props.user.referral) {
			const referral: { data: { notes: string, payee_id: number, status: string, store: string, commision: number } } = await axios({
				url: "https://us-central1-dtl-prism.cloudfunctions.net/app/lead/" + this.props.user.referral,
				method: "GET",
				auth: {
					username: "dtloans",
					password: "*Mauricio2019",
				},
			});

			if (referral.data) {
				const payeeID = referral.data.payee_id;
				const payee: { data: { userData: Object } } = await axios({
					url: "https://us-central1-dtl-prism.cloudfunctions.net/app/userData/" + payeeID,
					method: "GET",
					auth: {
						username: "dtloans",
						password: "*Mauricio2019",
					},
				});

				this.setState({
					referralNotes: referral.data.notes,
					referralStatus: referral.data.status,
					referralStore: referral.data.store,
					referralCommission: "$" + referral.data.commision,
					referrer: payeeID,
					referrerName: payee.data.userData.displayName,
					referrerEmail: payee.data.userData.email,
					referrerLastLogin: payee.data.userData.metadata.lastSignInTime,
				});
			}
		} else {
			const referrals: {
				data: Array<{
					payee: string,
					created: string,
				}>,
			} = await axios({
				url: "https://us-central1-dtl-prism.cloudfunctions.net/app/leads",
				method: "GET",
				auth: {
					username: "dtloans",
					password: "*Mauricio2019",
				},
			});

			this.setState({
				referrals: referrals.data.reverse().slice(0, 60),
				loading: false,
			});
		}
	};

	unlink = () => {
		Modal.confirm({
			title: "Please Confirm",
			content: "This will remove the referral, you will have to link again",
			onOk: async () => {
				await firestore.doc("users/" + this.props.uid).update({
					referral: null,
				});
				await this.props.reload();
				await this.getData();
			},
		});
	};

	render() {
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
					<Typography.Title level={1}>
						Referral{" "}
						{this.props.user.referral ? (
							<Button type={"link"} onClick={this.unlink}>
								Unlink
							</Button>
						) : null}
					</Typography.Title>
					<Data state={this.state} props={this.props} />
					<Referrals
						state={this.state}
						props={this.props}
						onMatch={async () => {
							await this.props.reload();
							await this.getData();
						}}
					/>
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

//$FlowFixMe
export default connect(mapStateToProps)(Referrer);
