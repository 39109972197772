import moment from "moment";
import { Link } from "react-router-dom";
import { Select, Tag } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";

export const columns = (visible: boolean, setVisible: Function, bonus: number, setBonus: Function, getData: Function) => {
	return [
		{
			title: "Source",
			dataIndex: "source",
			key: "source",
			filters: [
				{
					text: "C2B",
					value: "C2B",
				},
				{
					text: "B2B",
					value: "B2B",
				},
			],
			width: 10,
			onFilter: (value: { source: string }, record: any) => record.source.indexOf(value) === 0,
			sorter: (a: any, b: any) => a.source.localeCompare(b.source),
		},
		{
			title: "Date Added",
			dataIndex: "date",
			key: "date",
			sorter: (a: any, b: any) => moment(a.date).isBefore(moment(b.date)),
			defaultSortOrder: "descend",
			render: (date: string) => moment(date).format("lll"),
		},
		{
			title: "Applicant's Name",
			key: "name",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
			render: (referral: any) => {
				if (referral.user) {
					return <Link to={referral.user + "/information"}>{referral.firstName}</Link>;
				}
				return referral.firstName + " " + referral.lastName;
			},
		},
		{
			title: "Applicant's Phone",
			dataIndex: "phone",
			sorter: (a: any, b: any) => a.phone.localeCompare(b.phone),
			key: "phone",
		},
		{
			title: "Referred By",
			dataIndex: "phone",
			sorter: (a: any, b: any) => a.phone.localeCompare(b.phone),
			key: "phone",
		},
		{
			title: "Step",
			dataIndex: "step",
			sorter: (a: any, b: any) => a.step - b.step,
		},
		{
			title: "Bonus",
			dataIndex: "bonus",
			key: "bonus",
			sorter: (a: any, b: any) => a["bonus"] - b["bonus"],
			render: (bonus: number) => <p>${bonus}</p>,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			sorter: (a: any, b: any) => a.status.localeCompare(b.status),
			filters: [
				{
					text: "Approved",
					value: "Approved",
				},
				{
					text: "Pending",
					value: "Pending",
				},
				{
					text: "No Loan",
					value: "No Loan",
				},
			],
			onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
			render: (status: any, record: any) => {
				const checkColor = () => {
					if (status === "Pending") {
						return (
							<Select
								defaultValue={status}
								onChange={async e => {
									await record?.ref?.update({ status: e });
									return getData();
								}}>
								<Select.Option key={0} value={"No Loan"}>
									No Loan
								</Select.Option>
								<Select.Option key={1} value={"Pending"}>
									Pending
								</Select.Option>
							</Select>
						);
					}
					if (status === "No Loan") {
						return (
							<Tag icon={<ExclamationCircleOutlined />} color={"red"}>
								{status}
							</Tag>
						);
					}
					if (status === "Paid") {
						return <Tag color={"default"}>{status}</Tag>;
					}
					if (status === "Approved") {
						return (
							<Tag icon={<CheckCircleOutlined />} color={"green"}>
								{status}
							</Tag>
						);
					}
					return null;
				};
				return checkColor();
			},
		},
	];
};
