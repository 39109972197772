import React, { useEffect } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStores } from "./redux/actions/stores";
import history from "./history";
import "./assets/fonts/fonts.css";
import "./App.css";

import AppContent from "./routes";
import AllContent from "./routes/dashboard";
import Login from "./routes/login";
import Users from "./routes/users";
import Profile from "./routes/profile";
import StoreFront from "./routes/storefront";
import U130 from "./routes/u130";
import Blackbook from "./routes/blackbook";
import Alerts from "./routes/alerts";
import CheckLogs from "./routes/check_logs";
import Payees from "./routes/payees";
import Tracker from "./routes/tracker";
import C2BReferrals from "./routes/referrals";
import SendReferrals from "./routes/send_referrals";
import B2BReferrals from "./routes/b2b_referrals";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (auth) {
				return <Component {...props} />;
			} else {
				return <Redirect to="/login" />;
			}
		}}
	/>
);

export default () => {
	const dispatch = useDispatch();
	const auth = useSelector(state => state.auth);

	useEffect(() => {
		dispatch(getStores());
	}, [dispatch]);

	return (
		//$FlowFixMe
		<Router history={history}>
			<Switch>
				<Route path="/login" exact component={Login} />
				<PrivateRoute path="/" exact component={AllContent} auth={auth} />
				<PrivateRoute path="/users" exact component={Users} auth={auth} />
				<PrivateRoute path="/new" exact component={StoreFront} auth={auth} />
				<PrivateRoute path="/c2b-referrals" exact component={C2BReferrals} auth={auth} />
				<PrivateRoute path="/b2b-referrals" exact component={B2BReferrals} auth={auth} />
				<PrivateRoute path="/alerts" exact component={Alerts} auth={auth} />
				<PrivateRoute path="/profile" exact component={Profile} auth={auth} />
				<PrivateRoute path="/u130" exact component={U130} auth={auth} />
				<PrivateRoute path="/blackbook" exact component={Blackbook} auth={auth} />
				<PrivateRoute path="/send-referrals" exact component={SendReferrals} auth={auth} />
				<PrivateRoute path="/check-logs" exact component={CheckLogs} auth={auth} />
				<PrivateRoute path="/tracker" exact component={Tracker} auth={auth} />
				<PrivateRoute path="/payees" exact component={Payees} auth={auth} />
				<PrivateRoute path="/:uid" component={AppContent} auth={auth} />
			</Switch>
		</Router>
	);
};
