import React from "react";
import AppHeader from "../../components/appHeader";

function Index() {
	return (
		<div>
			<AppHeader />
			<div style={{ display: "flex", minHeight: "100vh" }}>
				<iframe
					title={"DTL Store Front"}
					style={{ flex: 1, border: 0 }}
					src={"https://dtl-store-front.vercel.app/?key=4UDY5x4fnbQXnT7C6Fn7w25Sp5JQCM"}
				/>
			</div>
		</div>
	);
}

export default Index;
