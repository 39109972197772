import axios from "axios";

type Application = {
	applicationSource: string,
	application: {
		timestamp: {
			_seconds: number,
		},
		loan: number,
		homeZip: number,
		homeCity: string,
	},
	user: {
		firstName: string,
		lastName: string,
	},
	admin: {
		notes: string,
	},
};

export function getApplications(status: string): Promise<Array<Application>> {
	return new Promise(resolve => {
		return (
			axios
				.get("https://us-central1-digital-loans.cloudfunctions.net/app/titleLoans", {
					params: {
						status,
					},
					headers: {
						Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c",
					},
				})
				//$FlowFixMe
				.then((data: { data: any }) => {
					return resolve(
						data.data.map(item => {
							return {
								uid: item.user.uid,
								source: item.applicationSource,
								store: item.application.store?.name ?? null,
								name: item.user.firstName + " " + item.user.lastName,
								city: item.application.homeCity,
								step: item.application.status,
								exported: item.admin.exported,
								creditLimit: item.admin.creditLimit,
								adminLoan: item.admin.loan,
								applicationLoan: item.application.loan,
								notes: item.admin.notes,
								updated: item.user.timestamp?._seconds,
								date: item.application.timestamp?._seconds,
							};
						})
					);
				})
		);
	});
}
