import React, { useEffect, useState } from "react";
import { Layout, Table, Tag, Typography } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
//$FlowFixMe
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../firebase";
import moment from "moment";
import { useSelector } from "react-redux";

const UserReferrals = () => {
	const [referrals, setReferrals] = useState([]);
	const uid = useSelector(state => state.auth.uid);
	const [value, loading] = useCollectionOnce(firestore.collection("users/" + uid + "/referrals"));
	useEffect(() => {
		if (!loading) {
			setReferrals(
				value.docs.map(item => {
					return {
						...item.data(),
						date: moment(item.data().timestamp.toDate()).format("lll"),
					};
				})
			);
		}
	}, [loading, value]);
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Bonus",
			dataIndex: "bonus",
			key: "bonus",
			render: bonus => <p>${bonus}</p>,
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: status => {
				const checkColor = () => {
					if (status === "Pending") {
						return (
							<Tag icon={<ClockCircleOutlined />} color={"default"}>
								{status}
							</Tag>
						);
					}
					if (status === "No Loan") {
						return (
							<Tag icon={<ExclamationCircleOutlined />} color={"red"}>
								{status}
							</Tag>
						);
					}
					if (status === "Approved") {
						return (
							<Tag icon={<CheckCircleOutlined />} color={"green"}>
								{status}
							</Tag>
						);
					}
					return null;
				};
				return checkColor();
			},
		},
	];
	return (
		<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
			<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
				<Typography.Title level={1}>User Referrals</Typography.Title>
				<Table dataSource={referrals} loading={loading} pagination={{ pageSize: 50 }} columns={columns} rowKey={key => key} />
			</div>
		</Layout.Content>
	);
};

export default UserReferrals;
