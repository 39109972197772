import async from "async";

export default async function(users: Array<Object>) {
	const cities = [];
	const steps = [];
	await async.eachOfSeries(users, (user, key, callback) => {
		const city = user.city;
		const step = user.step;

		const cityObj = {
			value: city,
			text: city,
		};
		const stepObj = {
			value: step,
			text: step,
		};

		if (cities.filter((x: Object) => x.value === city).length === 0) {
			cities.push(cityObj);
		}

		if (steps.filter((x: Object) => x.value === step).length === 0) {
			steps.push(stepObj);
		}
		callback();
	});

	return { cities, steps };
}
