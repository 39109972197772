import { auth, firestore } from "../../firebase";
import history from "../../history";

export function login(payload: any) {
	return async (dispatch: any) => {
		const doc = await firestore.doc("adminUsers/" + payload.uid).get();
		if (!doc.exists) {
			return logout();
		}
		dispatch({
			type: "LOGIN",
			payload: {
				uid: payload.uid,
				photoURL: payload.photoURL,
				name: doc.data().name,
				email: payload.email,
				role: doc.data().role,
			},
		});
		return history.push("/");
	};
}

export function updateUserPhoto(url: string) {
	return async (dispatch: any) => {
		dispatch({
			type: "UPDATE_PHOTO",
			payload: url,
		});
	};
}

export function logout() {
	return async (dispatch: any) => {
		await auth.signOut();
		dispatch({ type: "LOGOUT" });
		dispatch({ type: "REMOVE_STORES" });
		return history.push("/login");
	};
}
