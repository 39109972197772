import React from "react";
import { Col, Row, Typography } from "antd";
// noinspection ES6CheckImport
import { SRLWrapper } from "simple-react-lightbox";

function Pictures(props: { pictures: Array<string> }) {
	return (
		<Row style={{ paddingTop: 25, paddingBottom: 40 }} gutter={[1, 10]}>
			<Col span={24}>
				<Typography.Title level={3}>Vehicle Pictures</Typography.Title>
			</Col>

			<Col span={24}>
				{props.pictures && props.pictures.length ? (
					<SRLWrapper>
						{props.pictures.map(file => (
							<a href={file} data-attribute="SRL" key={file}>
								<img style={style.responsiveImg} alt="images" src={file} />
							</a>
						))}
					</SRLWrapper>
				) : null}
			</Col>
		</Row>
	);
}

const style = {
	responsiveImg: {
		width: 120,
		height: 120,
		margin: 5,
	},
};

export default Pictures;
