import React, { Component } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { firestore } from "../firebase";
import { connect } from "react-redux";

import Menu from "../components/menu";
import Footer from "../components/footer";
import Loading from "../components/loading";
import Information from "./information";
import References from "./references";
import Verifications from "./verifications";
import Loan from "./loan";
import Referral from "./referral";
import Vehicle from "./vehicle";
import Files from "./files";
import UserReferrals from "./userReferrals";
import type { Props, State } from "./types";

class Index extends Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			uid: props.match.params.uid,
			loading: true,
			applications: [],
			user: null,
			admin: null,
		};
	}

	componentDidMount(): void {
		this.getData(this.state.uid).catch();
	}

	reload = () => {
		return this.getData(this.state.uid);
	};

	getData = async (uid: string) => {
		const userRef = firestore.doc("users/" + uid);
		const user = await userRef.get();
		const admin = await firestore.doc("admin/" + uid).get();

		const application = await firestore.doc("applications/" + uid).get();
		const applications = await firestore
			.collection("applications")
			.where("user", "==", userRef)
			.get();

		const allApplications = [
			{
				key: application.id,
				...application.data(),
			},
		].concat(
			applications.docs.map(x => {
				return {
					key: x.id,
					...x.data(),
				};
			})
		);

		this.setState({
			user: user.data(),
			admin: admin.data(),
			applications: allApplications,
			loading: false,
		});
		return true;
	};

	render() {
		return (
			<Layout className={"layout"} style={{ minHeight: "100vh" }}>
				<Menu match={this.props.match} user={this.state.user} admin={this.state.admin} applications={this.state.applications} />
				{this.state.loading ? (
					<Loading active={true} />
				) : (
					<Layout style={{ marginLeft: 200 }}>
						<Switch>
							<Route
								path={this.props.match.url + "/information"}
								exact
								render={() => (
									<Information
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
										reload={this.reload}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/references"}
								exact
								render={() => <References applications={this.state.applications} reload={this.reload} />}
							/>
							<Route
								path={this.props.match.url + "/verifications"}
								exact
								render={() => (
									<Verifications
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/loans/:loanID"}
								exact
								render={props => (
									<Loan
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
										reload={this.reload}
										{...props}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/userReferrals"}
								exact
								render={() => (
									<UserReferrals
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/referral"}
								exact
								render={() => (
									<Referral
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
										reload={this.reload}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/vehicle"}
								exact
								render={() => (
									<Vehicle
										user={this.state.user}
										applications={this.state.applications}
										admin={this.state.admin}
										uid={this.state.uid}
										reload={this.reload}
									/>
								)}
							/>
							<Route
								path={this.props.match.url + "/files"}
								exact
								render={() => (
									<Files user={this.state.user} applications={this.state.applications} admin={this.state.admin} uid={this.state.uid} />
								)}
							/>
						</Switch>
						<Footer />
					</Layout>
				)}
			</Layout>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const dispatchToProps = () => {
	return {};
};

//$FlowFixMe
export default connect(mapStateToProps, dispatchToProps)(Index);
