const steps = [
	{ id: 0, name: "Profile Completed" },
	{ id: 1, name: "Finished Application" },
	{ id: 2, name: "Finished Discounts" },
	{ id: 3, name: "Accepted Offer" },
	{ id: 4, name: "Uploaded Documents/Review Needed" },
	{ id: 5, name: "Pre Approved" },
	{ id: 6, name: "Appointment Scheduled" },
	{ id: 7, name: "Collect References" },
	{ id: 8, name: "Enable E-sign" },
	{ id: 9, name: "Contract Signed" },
];

export default steps;
