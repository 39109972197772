/* eslint max-lines: 0 */
import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import axios from "axios";
import { url } from "./config";
import moment from "moment";
import { useSelector } from "react-redux";

const TrackerModel = (props: Object) => {
	const [form] = Form.useForm();
	const [loadingState, setLoadingState] = useState(false);
	const allStores = useSelector(state => state.stores);
	const stores = allStores.filter(x => !x.online);

	const onComponentMountAsync = () => {
		if (props.edit) {
			form.setFieldsValue({ ...props.edit, date: moment(props.edit.data) });
		} else {
			form.resetFields();
		}
	};

	useEffect(onComponentMountAsync, [onComponentMountAsync]);

	const onFinish = async values => {
		setLoadingState(true);
		delete values.cd;
		if (props.edit) {
			// Update log
			await axios
				.put(`${url}/${props.edit.id}`, {
					...values,
					date: values.date.format("YYYY-MM-DD HH:mm:ss"),
					user_id: 0,
					created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
					updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
				})
				.catch(e => notification.error({ title: "Whoops! Something went wrong", message: e.message }));
			form.resetFields();
			setLoadingState(false);
			props.reload();
			return props.close();
		} else {
			// Create log
			await axios
				.post(url, {
					working_day: 0,
					...values,
					date: values.date.format("YYYY-MM-DD HH:mm:ss"),
					user_id: 0,
					created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
					updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
				})
				.catch(e => notification.error({ title: "Whoops! Something went wrong", message: e.message }));
			form.resetFields();
			setLoadingState(false);
			props.reload();
			return props.close();
		}
	};

	const onValuesChange = (a, values) => {
		if (values.value_new_loans || values.value_re_loans || values.value_up_loans) {
			form.setFieldsValue({
				cd: parseFloat(values.value_up_loans ?? 0) + parseFloat(values.value_new_loans ?? 0) + parseFloat(values.value_re_loans ?? 0),
			});
		}
	};

	return (
		<Modal
			forceRender
			title={"Create entry"}
			visible={props.visible}
			width={800}
			onCancel={() => {
				props.close();
			}}
			footer={null}>
			<Form form={form} onFinish={onFinish} onValuesChange={onValuesChange} initialValues={""} layout={"vertical"}>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item label={"Store"} name={"store_id"}>
							<Select>
								{stores.map(store => {
									return (
										<Select.Option value={store.prismID} key={store.id}>
											{store.preName} {store.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Date"}
							name={"date"}
							rules={[
								{
									required: true,
									message: "Please input date.",
								},
							]}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Loan Balance"}
							name={"loan_balance"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Cab Fees"}
							name={"cab_fees"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Number Of New Loans"}
							name={"new_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Dollar value Of New Loans"}
							name={"value_new_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Number Of Reloans"}
							name={"re_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Dollar value Of Reloans"}
							name={"value_re_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Number Of Uploans"}
							name={"up_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Dollar value Of Uploans"}
							name={"value_up_loans"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={"Capital Deployment"} name={"cd"}>
							<InputNumber disabled style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Current"}
							name={"current_due"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"1-14 Days"}
							name={"due_1_14"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"15-29 Days"}
							name={"due_15_29"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"30-59 Days"}
							name={"due_30_45"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Write-Offs(Running Total)"}
							name={"due_write_off"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={"Active Promises"}
							name={"active_promises"}
							rules={[
								{
									required: true,
									message: "Please input a value.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label={"Notes"} name={"notes"}>
							<Input.TextArea rows={8} />
						</Form.Item>
					</Col>
					<Col span={2}>
						<Form.Item>
							<Button htmlType={"submit"} loading={loadingState} type={"primary"}>
								Save
							</Button>
						</Form.Item>
					</Col>
					<Col align={"left"} span={19}>
						<Form.Item>
							<Button onClick={props.close}>Cancel</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default TrackerModel;
