import React from "react";
import AppHeader from "../../components/appHeader";
import { Typography, Input, Button, Modal, Row, Col } from "antd";
import axios from "axios";

type Props = {};

type State = {
	id: string,
	loading: boolean,
};

class Index extends React.Component<Props, State> {
	state = {
		id: "",
		loading: false,
	};

	download = async (): any => {
		if (this.state.id) {
			await this.setState({ loading: true });
			axios
				.get("https://us-central1-digital-loans.cloudfunctions.net/u130/infinity/" + this.state.id)
				.then(res => {
					this.setState({ loading: false });
					return window.open(res.data, "_blank");
				})
				.catch(err => {
					Modal.error({ title: "Something is wrong", content: err.response.data });
					this.setState({ loading: false });
				});
		}
	};

	downloadEmpty = async (): any => {
		const token = "&token=2c72f84d-2ede-4f75-b67c-76ee3a769ed3";
		const url = "https://firebasestorage.googleapis.com/v0/b/digital-loans.appspot.com/o/files%2Fu130.pdf?alt=media" + token;
		return window.open(url, "_blank");
	};

	render() {
		return (
			<div>
				<AppHeader />
				<div style={{ padding: 30, paddingTop: 200 }}>
					<Typography.Title>U130</Typography.Title>
					<Row gutter={[10, 10]}>
						<Col xs={24} md={4}>
							<Input placeholder={"Infinity User ID"} type={"number"} onChange={e => this.setState({ id: e.currentTarget.value })} />
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={24}>
							<Button type={"primary"} onClick={this.download} disabled={!this.state.id} loading={this.state.loading}>
								Download
							</Button>
							<Button type={"primary"} onClick={this.downloadEmpty} style={{ marginLeft: 10 }}>
								Download Blank Form
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default Index;
