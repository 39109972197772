import React from "react";
import AppHeader from "../../components/appHeader";

const B2BReferrals = () => {
	return (
		<div style={{ height: "100vh" }}>
			<AppHeader />
			<div style={{ padding: 30, paddingTop: 200, minHeight: "100vh" }}>
				<h1>In Progress...</h1>
			</div>
		</div>
	);
};

export default B2BReferrals;
