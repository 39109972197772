import React, { Component } from "react";
import { Layout, Menu, Divider, Typography } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "../components/icon";
import history from "../history";
import type { Application } from "../types/application";
import type { User } from "../types/user";
import type { Admin } from "../types/admin";

type Props = {
	user: User,
	admin: Admin,
	applications: Array<Application>,
	match: Object,
	logout: Function,
};

type State = {
	active: string,
};

function MenuItem(props: { url: string, icon?: string, page: string, title: string }) {
	return (
		<Link to={props.url + props.page}>
			{props.icon ? <Icon name={props.icon} /> : null}
			<span>{props.title}</span>
		</Link>
	);
}

class MenuComponent extends Component<Props, State> {
	listener = () => {};
	state = {
		active: "information",
	};

	componentDidMount(): void {
		this.handle();
		this.listener = history.listen(() => {
			this.handle();
		});
	}

	componentWillUnmount(): void {
		this.listener();
	}

	handle = () => {
		const active = history.location.pathname.split("/")[2];
		const loanActive = history.location.pathname.split("/")[3];
		if (loanActive) {
			this.setState({ active: active + "/" + loanActive });
		} else {
			this.setState({ active });
		}
	};

	render() {
		return (
			<Layout.Sider
				theme={"light"}
				style={{
					height: "100vh",
					position: "fixed",
				}}>
				<img
					src={require("../assets/logo.png")}
					alt={"Discount Loans Online"}
					width={"100%"}
					style={{ padding: 10, marginBottom: 20, backgroundColor: "#F1F2F5" }}
					onClick={() => history.replace("/")}
				/>
				<div style={{ paddingLeft: 0, textAlign: "center" }}>
					<Typography.Title level={3} style={{ color: "white" }}>
						{this.props.user ? this.props.user.firstName + " " + this.props.user.lastName : null}
					</Typography.Title>
					<Typography.Text style={{ color: "white" }}>
						{this.props.user ? this.props.user.email : null} <br />
						{this.props.user ? this.props.user.phone : null}
					</Typography.Text>
					<br />
					{process.env.NODE_ENV === "development" ? (
						<>
							<br />
							<Typography.Text style={{ color: "white" }}>{history.location.pathname.split("/")[1]}</Typography.Text>
							<br />
						</>
					) : null}
					<br />
					<Link to={"/"} style={{ color: "#fff" }}>
						<u>Close</u>
					</Link>
				</div>
				<Divider style={{ opacity: 0.2 }} />
				<Menu theme="dark" selectedKeys={[this.state.active]} mode="inline">
					<Menu.Item key="information">
						<MenuItem title={"Customer's Information"} icon={"user"} page={"/information"} url={this.props.match.url} />
					</Menu.Item>
					<Menu.Item key="references">
						<MenuItem title={"References"} icon={"users"} page={"/references"} url={this.props.match.url} />
					</Menu.Item>
					{/*<Menu.Item key="verifications">*/}
					{/*	<MenuItem title={"Verifications"} icon={"check-circle"} page={"/verifications"} url={this.props.match.url} />*/}
					{/*</Menu.Item>*/}
					<Menu.SubMenu
						key="loans"
						title={
							<>
								<Icon name="money-check-edit-alt" />
								<span>Loans</span>
							</>
						}>
						{this.props.applications.map(application => {
							return (
								<Menu.Item key={"loans/" + application.key}>
									<MenuItem
										title={application.key.substr(application.key.length - 4).toUpperCase()}
										page={"/loans/" + application.key}
										url={this.props.match.url}
									/>
								</Menu.Item>
							);
						})}
					</Menu.SubMenu>
					<Menu.Item key="userReferrals">
						<MenuItem title={"Customer's Referrals"} icon={"file"} page={"/userReferrals"} url={this.props.match.url} />
					</Menu.Item>
					{(this.props.user && this.props.user.referral) || (this.props.admin && this.props.admin.heard === "B2B Referral") ? (
						<Menu.Item key="referral">
							<MenuItem title={"Referral"} icon={"link"} page={"/referral"} url={this.props.match.url} />
						</Menu.Item>
					) : null}
					<Menu.Item key="vehicle">
						<MenuItem title={"Vehicle"} icon={"car-side"} page={"/vehicle"} url={this.props.match.url} />
					</Menu.Item>
					<Menu.Item key="files">
						<MenuItem title={"Files"} icon={"folder-open"} page={"/files"} url={this.props.match.url} />
					</Menu.Item>
				</Menu>
			</Layout.Sider>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const dispatchToProps = () => {
	return {};
};

//$FlowFixMe
export default connect(mapStateToProps, dispatchToProps)(MenuComponent);
