import React, { useEffect, useState } from "react";
import AppHeader from "../../components/appHeader";
import { Col, Row, Typography } from "antd";
import Footer from "../../components/footer";
import { columns } from "./columns";
import axios from "axios";
import SmartTable from "../../components/smartTable";

export default () => {
	const [state, setState] = useState({
		payees: [],
		loading: false,
	});

	const onComponentMountAsync = () => {
		setState({ ...state, loading: true });
		axios
			.get("https://us-central1-digital-loans.cloudfunctions.net/c2bPayees")
			.then(payees => {
				return setState({ ...state, payees: payees.data, loading: false });
			})
			.catch();
	};

	useEffect(onComponentMountAsync, []);

	return (
		<div style={{ backgroundColor: "#F1F2F5", minHeight: "100vh" }}>
			<AppHeader />
			<div style={{ paddingTop: 200, paddingLeft: 30, paddingRight: 30, backgroundColor: "#FFF", minHeight: "100vh" }}>
				<Row style={{ display: "flex", alignItems: "center" }}>
					<Col span={12}>
						<Typography.Title level={1}>Payees</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12} />
				</Row>
				<SmartTable rowKey={({ id }) => id} loading={state.loading} dataSource={state.payees} columns={columns()} />
			</div>
			<Footer />
		</div>
	);
};
