import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Divider, Layout, Typography, Button } from "antd";
import Reference from "./reference";
import type { Application } from "../../types/application";
import type { Reference as ReferenceType } from "../../types/reference";
import { firestore } from "../../firebase";

type Props = {
	applications: Array<Application>,
	reload: Function,
};
type State = {
	loading: boolean,
	references: Array<ReferenceType | null>,
};

class References extends PureComponent<Props, State> {
	state = {
		loading: false,
		references: [
			this.props.applications[0].references ? this.props.applications[0].references[0] : null,
			this.props.applications[0].references ? this.props.applications[0].references[1] : null,
			this.props.applications[0].references ? this.props.applications[0].references[2] : null,
			this.props.applications[0].references ? this.props.applications[0].references[3] : null,
		],
	};

	_referenceChanged = (reference: ReferenceType, id: number) => {
		const references = [...this.state.references];
		references[id] = reference;
		this.setState({ references });
	};

	save = async (): any => {
		await this.setState({ loading: true });
		await firestore.doc("applications/" + this.props.applications[0].key).update({
			references: this.state.references,
		});
		await this.setState({ loading: false });
		this.props.reload();
	};

	render() {
		const references = this.props.applications[0].references;
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
					<Typography.Title level={1}>References</Typography.Title>
					<Reference reference={references ? references[0] : null} onChanged={this._referenceChanged} id={0} />
					<Divider />
					<Reference reference={references ? references[1] : null} onChanged={this._referenceChanged} id={1} />
					<Divider />
					<Reference reference={references ? references[2] : null} onChanged={this._referenceChanged} id={2} />
					<Divider />
					<Reference reference={references ? references[3] : null} onChanged={this._referenceChanged} id={3} />
					<Button type={"primary"} onClick={this.save} loading={this.state.loading}>
						Update
					</Button>
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

//$FlowFixMe
export default connect(mapStateToProps)(References);
