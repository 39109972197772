const heard = [
	"Facebook",
	"B2B Referral",
	"Billboard",
	"Customer Referral",
	"Drive-By / Store Sign",
	"Google Search Engine / Ads",
	"On-line Yellow Pages",
	"Other",
	"TV Ads",
	"Web-site",
	"Word of Mouth",
];

export default heard;
