import React, { Component } from "react";
import { Row, Col, Select } from "antd";
import axios from "axios";

type Props = {
	onChange: Function,
};

type State = {
	uvc?: string,
	years: Array<Object>,
	makes: Array<Object>,
	models: Array<Object>,
	trims: Array<Object>,
	styles: Array<Object>,
	year: string,
	make: string,
	model: string,
	trim: string,
	style: string,
};

export default class Vehicle extends Component<Props, State> {
	state = {
		uvc: "",
		years: [],
		makes: [],
		models: [],
		trims: [],
		styles: [],
		year: "",
		make: "",
		model: "",
		trim: "",
		style: "",
	};

	async componentDidMount() {
		const blackbook = await axios({
			method: "GET",
			url: "https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/Drilldown/ALL?drilldeep=true&getclass=false&customerid=DTLPrism",
			params: {
				customerid: "DTL-Digital-BlackbookPage",
			},
			headers: {
				Authorization: "Basic ZHRpdGxlMTpkdGl0bGUx",
			},
		});

		const data = blackbook.data["drilldown"]["class_list"][0];
		this.setState({
			years: data["year_list"],
			makes: data["year_list"][0]["make_list"],
			models: data["year_list"][0]["make_list"][0]["model_list"],
			trims: data["year_list"][0]["make_list"][0]["model_list"][0]["series_list"],
			styles: data["year_list"][0]["make_list"][0]["model_list"][0]["series_list"][0]["style_list"],
			uvc: data["year_list"][0]["make_list"][0]["model_list"][0]["series_list"][0]["style_list"][0]["uvc"],
		});
	}

	componentDidUpdate(prevProps: any, prevState: any, snapshot: any): void {
		this.props.onChange(this.state.uvc);
	}

	_changeYear = (e: string) => {
		this.setState(
			{
				year: e,
				make: "",
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeMake = (e: string) => {
		this.setState(
			{
				make: e,
				model: "",
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeModel = (e: string) => {
		this.setState(
			{
				model: e,
				trim: "",
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_changeTrim = (e: string) => {
		this.setState(
			{
				trim: e,
				style: "",
			},
			() => this._reloadVehicles()
		);
	};
	_reloadVehicles = async () => {
		await this.setState({
			// $FlowFixMe
			makes: this.state.year ? this.state.years.find(x => x.name === this.state.year)["make_list"] : this.state.years[0]["make_list"],
		});
		await this.setState({
			// $FlowFixMe
			models: this.state.make ? this.state.makes.find(x => x.name === this.state.make)["model_list"] : this.state.makes[0]["model_list"],
		});
		await this.setState({
			// $FlowFixMe
			trims: this.state.model ? this.state.models.find(x => x.name === this.state.model)["series_list"] : this.state.models[0]["series_list"],
		});
		await this.setState({
			// $FlowFixMe
			styles: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim)["style_list"] : this.state.trims[0]["style_list"],
		});
		await this.setState({
			// $FlowFixMe
			uvc: this.state.trim ? this.state.trims.find(x => x.name === this.state.trim)["style_list"][0]["uvc"] : this.state.styles[0]["uvc"],
		});
	};
	render() {
		return (
			<Row gutter={10}>
				<Col span={6}>
					Car Year:
					<Select style={{ width: "100%", marginBottom: 12 }} onChange={this._changeYear}>
						{this.state.years.map(item => {
							return (
								<Select.Option key={item.name} value={item.name}>
									{item.name}
								</Select.Option>
							);
						})}
					</Select>
				</Col>
				<Col span={6}>
					Car Make:
					<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.year} onChange={this._changeMake}>
						{this.state.makes.map(item => {
							return (
								<Select.Option key={item.name} value={item.name}>
									{item.name}
								</Select.Option>
							);
						})}
					</Select>
				</Col>
				<Col span={6}>
					Car Model:
					<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.make} onChange={this._changeModel}>
						{this.state.models.map(item => {
							return (
								<Select.Option key={item.name} value={item.name}>
									{item.name}
								</Select.Option>
							);
						})}
					</Select>
				</Col>
				<Col span={6}>
					Car Style:
					<Select style={{ width: "100%", marginBottom: 12 }} disabled={!this.state.model} onChange={this._changeTrim}>
						{this.state.trims.map(item => {
							return (
								<Select.Option key={item.name} value={item.name}>
									{item.name}
								</Select.Option>
							);
						})}
					</Select>
				</Col>
			</Row>
		);
	}
}
