import type { State, Props } from "./types";
import { Col, Row, Statistic } from "antd";
import moment from "moment";
import React from "react";

export default (props: { state: State, props: Props }) => {
	if (!props.props.user.referral) {
		return <div />;
	}
	return (
		<>
			<Row gutter={[20, 20]}>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referral ID" value={props.props.user.referral} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referral Status" value={props.state.referralStatus} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referral Commission" value={props.state.referralCommission} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referral Store" value={props.state.referralStore} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referrer ID" value={props.state.referrer} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referrer Name" value={props.state.referrerName} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referrer Email" value={props.state.referrerEmail} />
				</Col>
				<Col span={6}>
					<Statistic groupSeparator={""} title="Referrer Last Login" value={moment(props.state.referrerLastLogin).format("lll")} />
				</Col>
				<Col span={24}>
					<Statistic groupSeparator={""} title="Referral Notes" value={props.state.referralNotes} />
				</Col>
			</Row>
		</>
	);
};
