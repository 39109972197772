import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import axios from "axios";
import AppHeader from "../../components/appHeader";
import Footer from "../../components/footer";
import { columns } from "./columns";
import { url } from "./config";
import TrackerModel from "./modal";
import SmartTable from "../../components/smartTable";

const Tracker = () => {
	const [state, setState] = useState({
		checks: [],
		loading: false,
		visible: false,
		data: null,
		edit: null,
		searchText: "",
		searchedColumn: "",
	});

	const onComponentMountAsync = () => {
		setState({ ...state, loading: true, visible: false, edit: null });
		axios
			.get(url)
			.then(res => setState({ ...state, data: res.data, loading: false, edit: false, visible: false }))
			.catch();
	};

	useEffect(onComponentMountAsync, []);

	const manipulateData = (data, option) => {
		if (option === "edit") {
			return setState({ ...state, edit: data, visible: true });
		}
		if (option === "delete") {
			axios.delete(`${url}/${data.id}`);
			return onComponentMountAsync();
		}
		return null;
	};

	const close = () => {
		setState({ ...state, edit: null, visible: false });
	};

	return (
		<div style={{ backgroundColor: "#F1F2F5", minHeight: "100vh" }}>
			<AppHeader />
			<TrackerModel
				visible={state.visible}
				edit={state.edit}
				reload={() => onComponentMountAsync()}
				close={() => {
					close();
				}}
			/>
			<div style={{ paddingTop: 200, paddingLeft: 30, paddingRight: 30, backgroundColor: "#FFF", minHeight: "100vh" }}>
				<Row style={{ display: "flex", alignItems: "center" }}>
					<Col span={12}>
						<Typography.Title level={1}>Daily Tracker</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12}>
						<Button type="primary" onClick={() => setState({ ...state, visible: true, edit: null })}>
							Create
						</Button>
					</Col>
				</Row>
				<SmartTable rowKey={({ id }) => id} loading={state.loading} dataSource={state.data} columns={columns(manipulateData)} />
			</div>
			<Footer />
		</div>
	);
};

export default Tracker;
