import { Button, InputNumber, Modal, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EyeFilled, FileFilled, SendOutlined } from "@ant-design/icons";
import { firestore } from "../../firebase";
import axios from "axios";
import React from "react";

export default (visible: boolean, setVisible: Function, bonus: number, setBonus: Function, getData: Function) => {
	return [
		{
			title: "Actions",
			render: (_: any, record: any) => {
				if (record.status === "No Loan") {
					return (
						<Popconfirm
							title={"Are you sure you want to delete this item?"}
							onConfirm={async () => {
								await firestore.doc(record.ref.path).delete();
								return getData();
							}}
							okText={"yes"}>
							<Tooltip title={"Delete"}>
								<Button type={"link"}>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					);
				}
				return (
					<>
						{record?.hasAccount ? (
							<Tooltip title={"View Application"}>
								<a href={`/${record.application}/information`} target="_top">
									<EyeFilled />
								</a>
							</Tooltip>
						) : (
							<a
								type={"link"}
								href={`https://dtl-store-front.vercel.app/?key=4UDY5x4fnbQXnT7C6Fn7w25Sp5JQCM&${
									record["application"] ? `applicationID=${record["application"]}` : `refID=${record.id}`
								}`}
								target={"_blank"}
								rel={"noopener noreferrer"}>
								{record?.["applicationRef"] ? (
									<Tooltip title={"Continue"}>
										<FileFilled />
									</Tooltip>
								) : (
									<Tooltip title={"Start Application"}>
										<FileFilled />
									</Tooltip>
								)}
							</a>
						)}
						{record.bonus !== 0 && record.status === "Approved" ? (
							<Tooltip title={"Export to check logs"}>
								<Button
									onClick={() => {
										setVisible(true);
										Modal.confirm({
											title: "Enter bonus",
											visible: visible,
											content: <InputNumber style={{ width: "100%" }} onChange={e => setBonus(e)} placeholder={"Bonus"} />,
											onOk: () => {
												axios
													.post("https://us-central1-digital-loans.cloudfunctions.net/c2b/exportToCheckLog", {
														uid: record.ref.path.split("/")[1],
														referralID: record.id,
														bonus: record?.bonus === 0 ? bonus : record?.bonus,
													})
													.catch();
											},
										});
									}}
									type={"link"}>
									<SendOutlined />
								</Button>
							</Tooltip>
						) : null}
						<Popconfirm
							title={"Are you sure you want to delete this item?"}
							onConfirm={async () => {
								await firestore.doc(record.ref.path).delete();
								return getData();
							}}
							okText={"yes"}>
							<Tooltip title={"Delete"}>
								<Button type={"link"}>
									<DeleteOutlined />
								</Button>
							</Tooltip>
						</Popconfirm>
					</>
				);
			},
		},
	];
};
