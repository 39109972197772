import { Modal } from "antd";
import axios from "axios";

export function exportToInfinity(id: string) {
	return Modal.confirm({
		title: "Please Confirm",
		content: "This will export the application to Infinity",
		okText: "Export",
		onOk: async () => {
			return axios({
				method: "get",
				headers: { Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c" },
				url: "https://us-central1-digital-loans.cloudfunctions.net/exportToInfinity?id=" + id,
			}).catch(e => Modal.error({ title: "Something is wrong", content: e.response.data }));
		},
	});
}

export function deleteUser(id: string) {
	return Modal.confirm({
		title: "Please Confirm",
		content: "This will completely remove the user and application",
		okText: "Export",
		onOk: async () => {
			return axios({
				method: "delete",
				headers: { Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c" },
				url: "https://us-central1-digital-loans.cloudfunctions.net/app/user/" + id,
			}).catch(e => Modal.error({ title: "Something is wrong", content: e.response.data }));
		},
	});
}
