import React from "react";
import { Spin } from "antd";

function Loading(props: { active: boolean }) {
	if (props.active) {
		return (
			<div id="loading">
				<Spin size="large" />
			</div>
		);
	} else {
		return <div />;
	}
}

export default Loading;
