import { Layout, Typography, Input, Row, Col, Divider, Select, Button } from "antd";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import type { Props, State } from "./types";
import save from "./save";
import User from "./user";
import Files from "./files";
import steps from "../../data/steps";
import heard from "../../data/heard";

class Information extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		// Find Store ID
		const st = props.applications[0].store;
		const store = st && st.prismID ? st.prismID : null;
		const storeID = store ? props.stores.find(x => x.prismID === store)?.id : null;

		this.state = {
			loading: false,
			customerID: props.admin.customerID,
			loanID: props.admin.loanID,

			store: storeID ?? null,
			active: props.applications[0].active,
			status: props.applications[0].status,
			heard: props.admin.heard,
			gender: props.admin.gender ? props.admin.gender : null,
			vId: Boolean(props.admin.vId),
			vResidence: Boolean(props.admin.vResidence),

			firstName: props.user.firstName,
			lastName: props.user.lastName,
			phone: props.user.phone,
			ssn: props.user.ssn,

			homeAddress: props.applications[0].homeAddress,
			homeCity: props.applications[0].homeCity,
			homeState: props.applications[0].homeState,
			homeZip: props.applications[0].homeZip,
			mailingAddress: props.applications[0].mailingAddress,
			mailingCity: props.applications[0].mailingCity,
			mailingState: props.applications[0].mailingState,
			mailingZip: props.applications[0].mailingZip,
			notes: props.admin.notes,
		};
	}

	save = async (): any => {
		await this.setState({ loading: true });
		await save(this.state, this.props);
		await this.setState({ loading: false });
		this.props.reload();
	};

	render() {
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
					<Typography.Title level={1}>Information</Typography.Title>
					<Typography.Title level={3}>Infinity</Typography.Title>
					<Row gutter={[20, 25]}>
						<Col span={12}>
							<b>Infinity Customer ID</b>
							<Input value={this.state.customerID} onChange={e => this.setState({ customerID: e.currentTarget.value })} />
						</Col>
						<Col span={12}>
							<b>Infinity Loan ID</b>
							<Input value={this.state.loanID} onChange={e => this.setState({ loanID: e.currentTarget.value })} />
						</Col>
					</Row>
					<Divider />
					<Typography.Title level={3}>Administration</Typography.Title>
					<Row gutter={[20, 25]}>
						<Col span={12}>
							<b>Assign Store</b>
							<Select style={{ width: "100%" }} value={this.state.store} onChange={e => this.setState({ store: e })}>
								{this.props.stores.map(store => (
									<Select.Option value={store.id} key={store.id}>
										{store.preName} {store.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col span={12}>
							<b>Status</b>
							<Select style={{ width: "100%" }} value={this.state.active} onChange={e => this.setState({ active: e })}>
								<Select.Option value={1}>Open</Select.Option>
								<Select.Option value={2}>Loan Funded</Select.Option>
								<Select.Option value={3}>No Loan</Select.Option>
							</Select>
						</Col>
						<Col span={8}>
							<b>Application Step</b>
							<Select style={{ width: "100%" }} value={this.state.status} onChange={e => this.setState({ status: e })}>
								{steps.map(store => (
									<Select.Option value={store.id} key={store.id}>
										{store.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col span={8}>
							<b>How did they hear about us?</b>
							<Select style={{ width: "100%" }} value={this.state.heard} onChange={e => this.setState({ heard: e })}>
								{heard.map(source => (
									<Select.Option value={source} key={source}>
										{source}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col span={8}>
							<b>Gender</b>
							<Select style={{ width: "100%" }} value={this.state.gender} onChange={e => this.setState({ gender: e })}>
								<Select.Option value={"Male"}>Male</Select.Option>
								<Select.Option value={"Female"}>Female</Select.Option>
							</Select>
						</Col>
					</Row>
					<Divider />
					<User state={this.state} onChange={e => this.setState({ [e.name]: e.value })} />
					<Files {...this.props} />
					<br />
					<br />
					<b>Notes</b>
					<Input.TextArea value={this.state.notes} rows={6} onChange={e => this.setState({ notes: e.currentTarget.value })} />
					<br />
					<br />
					<Button type={"primary"} onClick={this.save} loading={this.state.loading}>
						Update
					</Button>
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps(state) {
	return {
		stores: state.stores,
	};
}

//$FlowFixMe
export default connect(mapStateToProps)(Information);
