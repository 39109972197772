import React from "react";

type Props = {
	latitude: any,
	longitude: any,
};

const Map = (props: Props) => {
	return (
		<>
			<iframe
				width="600"
				height="450"
				frameBorder="0"
				title="Google Map"
				style={{ border: 0 }}
				src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk&q=${props.latitude ? props.latitude : ""},${
					props.longitude ? props.longitude : ""
				}`}
				allowFullScreen
			/>
		</>
	);
};

export default Map;
