import React from "react";
import MaskedInput from "react-text-mask";
import { Row, Col, Input, Typography } from "antd";
import type { Reference } from "../../types/reference";

type Props = {
	id: number,
	onChanged: Function,
	reference: Reference | null,
};

export default class extends React.Component<Props, Reference> {
	state = {
		FirstName: this.props.reference ? this.props.reference.FirstName : "",
		LastName: this.props.reference ? this.props.reference.LastName : "",
		Relation: this.props.reference ? this.props.reference.Relation : "",
		Phone: this.props.reference ? this.props.reference.Phone : "",
		Address: this.props.reference ? this.props.reference.Address : "",
		City: this.props.reference ? this.props.reference.City : "",
		State: this.props.reference ? this.props.reference.State : "",
		Zip: this.props.reference ? this.props.reference.Zip : null,
	};

	handleTextChange = (e: Object) => {
		this.setState(
			{
				[e.target.name]: e.target.name === "Zip" ? parseFloat(e.target.value) : e.target.value,
			},
			() => {
				this.props.onChanged(this.state, this.props.id);
			}
		);
	};

	render() {
		return (
			<>
				<Typography.Title level={3}>Reference {this.props.id + 1}</Typography.Title>
				<Row gutter={[20, 25]}>
					<Col span={6}>
						First Name
						<Input value={this.state.FirstName} name="FirstName" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						Last Name
						<Input value={this.state.LastName} name="LastName" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						Relation
						<Input value={this.state.Relation} name="Relation" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						Phone Number
						<MaskedInput
							className={"ant-input"}
							mask={[/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
							value={this.state.Phone}
							name="Phone"
							onChange={this.handleTextChange}
						/>
					</Col>
					<Col span={6}>
						Address
						<Input value={this.state.Address} name="Address" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						City
						<Input value={this.state.City} name="City" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						State
						<Input value={this.state.State} name="State" onChange={this.handleTextChange} />
					</Col>
					<Col span={6}>
						Zip Code
						<MaskedInput
							className={"ant-input"}
							mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
							value={this.state.Zip}
							name="Zip"
							onChange={this.handleTextChange}
						/>
					</Col>
				</Row>
			</>
		);
	}
}
