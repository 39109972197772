import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Typography } from "antd";

class Verifications extends Component<{}> {
	render() {
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
					<Typography.Title level={1}>Verifications</Typography.Title>
					<p>Content of Tab Pane 1</p>
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

//$FlowFixMe
export default connect(mapStateToProps)(Verifications);
