import React, { useEffect, useState } from "react";
import AppHeader from "../../components/appHeader";
import { Button, Col, Form, Input, Modal, Row, Select, Table, Typography } from "antd";
import Footer from "../../components/footer";
import { firestore } from "../../firebase";
import moment from "moment";
import axios from "axios";

const Alerts = () => {
	const [form] = Form.useForm();
	const [state, setState] = useState({
		loading: true,
		btnLoading: false,
		modalVisible: false,
		notifs: [],
	});

	const getNotifs = () => {
		const load = async () => {
			setState({ ...state, loading: true });
			const url = "https://us-central1-dtl-prism.cloudfunctions.net/getNotifications";
			const getNotifsFromOtherDB = await axios.get(url);
			const notifs = await firestore.collection("notifications").get();
			setState({
				...state,
				notifs: [
					...notifs.docs.map(item => {
						return {
							...item.data(),
							id: item.id,
							app: "C2B App",
							date: item.data().timestamp.toDate(),
						};
					}),
					...getNotifsFromOtherDB.data.map(item => {
						return {
							...item,
							id: item.id,
							app: "B2B App",
							description: item.content,
							date: item.timestamp,
						};
					}),
				],
				loading: false,
			});
		};

		load().catch();
	};

	useEffect(getNotifs, []);

	const createAlert = async (values: { title: string, description: string, app: string }) => {
		if (values.app === "both") {
			setState({ ...state, btnLoading: true });
			await firestore.collection("notifications").add({ title: values.title, description: values.description });
			const url = "https://us-central1-dtl-prism.cloudfunctions.net/createNotifications";
			await axios.put(url, {
				body: values.description,
			});
			return setState({ ...state, btnLoading: false, modalVisible: false });
		}
		if (values.app === "c2bApp") {
			setState({ ...state, btnLoading: true });
			await firestore.collection("notifications").add({ title: values.title, description: values.description });
			return setState({ ...state, btnLoading: false, modalVisible: false });
		}
		if (values.app === "b2bApp") {
			const url = "https://us-central1-dtl-prism.cloudfunctions.net/createNotifications";
			await axios.put(url, {
				body: values.description,
			});
		}
		return null;
	};

	const onSelectAppChange = value => {
		switch (value) {
			case "b2bApp":
				form["setFieldsValue"]({ app: "b2bApp" });
				return;
			case "c2bApp":
				form["setFieldsValue"]({ note: "c2bApp" });
				return;
			case "both":
				form["setFieldsValue"]({ note: "both" });
				return;
			default:
				return;
		}
	};

	return (
		<div style={{ minHeight: "100vh" }}>
			<AppHeader />
			<Modal
				style={{ marginTop: 120 }}
				visible={state.modalVisible}
				onCancel={() => {
					setState({ ...state, modalVisible: false });
					form["resetFields"]();
				}}
				footer={() => null}>
				<Typography.Title level={2}>Create Alert</Typography.Title>
				<Form layout={"vertical"} form={form} onFinish={createAlert}>
					<Form.Item label={"Title"} name={"title"}>
						<Input />
					</Form.Item>
					<Form.Item label={"Select apps"} name={"app"}>
						<Select onChange={onSelectAppChange}>
							<Select.Option value={"b2bApp"} key={1}>
								B2B App
							</Select.Option>
							<Select.Option value={"c2bApp"} key={2}>
								C2B App
							</Select.Option>
							<Select.Option value={"both"} key={3}>
								Both
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item label={"Description"} name={"description"}>
						<Input.TextArea rows={5} />
					</Form.Item>
					<Button type={"primary"} htmlType={"submit"} loading={state.loading}>
						Create
					</Button>
				</Form>
			</Modal>
			<div style={{ padding: 30, paddingTop: 200, minHeight: "100vh" }}>
				<Row>
					<Col span={12}>
						<Typography.Title>Alerts</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12}>
						<Button type={"primary"} onClick={() => setState({ ...state, modalVisible: !state.modalVisible })} style={{ marginLeft: 10 }}>
							Create
						</Button>
					</Col>
				</Row>
				<Table
					dataSource={state.notifs}
					loading={state.loading}
					columns={[
						{
							title: "Date",
							dataIndex: "date",
							key: "date",
							defaultSortOrder: "descend",
							sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
							render: date => moment(date).format("ll"),
							width: 150,
						},
						{
							title: "Title",
							dataIndex: "title",
							key: "title",
							width: 200,
						},
						{
							title: "App",
							dataIndex: "app",
							key: "app",
							width: 100,
							filters: [
								{
									text: "B2B App",
									value: "B2B App",
								},
								{
									text: "C2B App",
									value: "C2B App",
								},
							],
							onFilter: (value, record) => record.app.indexOf(value) === 0,
						},
						{
							title: "Description",
							dataIndex: "description",
							key: "description",
							width: 400,
						},
					]}
					rowKey={item => item.id}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default Alerts;
