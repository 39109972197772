import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk",
	authDomain: "digital-loans.firebaseapp.com",
	databaseURL: "https://digital-loans.firebaseio.com",
	projectId: "digital-loans",
	storageBucket: "digital-loans.appspot.com",
	messagingSenderId: "691893147223",
	appId: "1:691893147223:web:3958f601a0003fe532f512",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

const secondary = firebase.initializeApp(firebaseConfig, "Secondary");
const firebaseAdmin = secondary.auth();

export { auth, firestore, functions, storage, firebaseAdmin };
