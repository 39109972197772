const discounts = [
	{
		name: "Name, Last Name or Address in uppercase?",
		value: 2.5,
		verify: false,
	},
	{
		name: "Name or Last Name in Email Address?",
		value: 2.5,
		verify: false,
	},
	{
		name: "Apple Device?",
		value: 2.5,
		verify: false,
	},
	{
		name: "Is your Loan Greater than or Equal to $500?",
		value: 0,
		verify: false,
	},
	{
		name: "Will you be giving us a GOOGLE Review?",
		value: 2.5,
		verify: true,
	},
	{
		name: "Does your Employer direct deposit your paycheck?",
		value: 2.5,
		verify: true,
	},
	{
		name: "Can you provide proof of income?",
		value: 5,
		verify: true,
	},
	{
		name: "Will you be signing up for Automatic Payments (Debit)?",
		value: 10,
		verify: true,
	},
	{
		name: "Can you provide us with a recent utility bill in your name?",
		value: 2.5,
		verify: true,
	},
	{
		name: "Employment over 2 years",
		value: 2.5,
		verify: true,
	},
	{
		name: "Current address over 2 years",
		value: 2.5,
		verify: true,
	},
	{
		name: "Can you provide us with a current mortgage statement in your name?",
		value: 10,
		verify: true,
	},
	{
		name: "Have you earned a referral bonus in the last 6 months?",
		value: 10,
		verify: true,
	},
	{
		name: "Previous Discount Title Loan customer in good standing (5+ payments)?",
		value: 15,
		verify: true,
	},
];

export default discounts;
