import { firestore } from "../../firebase";

export function getStores() {
	return async (dispatch: any) => {
		const storesCollection = await firestore
			.collection("title-stores")
			.orderBy("prismID")
			.get();
		dispatch({
			type: "SET_STORES",
			payload: storesCollection.docs.filter(x => !x.data().online).map(x => ({ ...x.data(), id: x.id })),
		});
	};
}
