import React from "react";
import { Checkbox, Col, Input, Row, Typography } from "antd";
import type { State } from "./types";
import MaskedInput from "react-text-mask";

function Details(props: { onChange: Function, state: State }) {
	return (
		<>
			<Row>
				<Col span={24}>
					<Typography.Title level={4}>Details</Typography.Title>
				</Col>
			</Row>
			<Row style={{ marginBottom: 10 }}>
				<Col span={4}>
					<Typography.Text strong>Make: </Typography.Text>
					<Typography.Text>{props.state.vMake}</Typography.Text>
					<br />
					<Typography.Text strong>Year: </Typography.Text>
					<Typography.Text>{props.state.vModelYear}</Typography.Text>
				</Col>
				<Col span={4}>
					<Typography.Text strong>Model: </Typography.Text>
					<Typography.Text>{props.state.vModel}</Typography.Text>
					<br />
					<Typography.Text strong>Series: </Typography.Text>
					<Typography.Text>{props.state.vSeries}</Typography.Text>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={12}>
					Color
					<Input value={props.state.color} onChange={e => props.onChange({ name: "color", value: e.currentTarget.value })} />
				</Col>
				<Col span={12}>
					Empty Weight
					<Input value={props.state.weight} onChange={e => props.onChange({ name: "weight", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					County
					<Input value={props.state.county} onChange={e => props.onChange({ name: "county", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					Owner Name
					<Input value={props.state.ownerName} onChange={e => props.onChange({ name: "ownerName", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					Owner City
					<Input value={props.state.ownerCity} onChange={e => props.onChange({ name: "ownerCity", value: e.currentTarget.value })} />
				</Col>
				<Col span={6}>
					Owner State
					<Input value={props.state.ownerState} onChange={e => props.onChange({ name: "ownerState", value: e.currentTarget.value })} />
				</Col>
				<Col span={24}>
					<Checkbox checked={props.state.coBorrower} onChange={e => props.onChange({ name: "coBorrower", value: e.target.checked })}>
						Co-borrower
					</Checkbox>
				</Col>
				{props.state.coBorrower ? (
					<>
						<Col span={5}>
							First Name
							<Input
								required={true}
								value={props.state.coBorrowerFirstName}
								onChange={e => props.onChange({ name: "coBorrowerFirstName", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={5}>
							Last Name
							<Input
								required={true}
								value={props.state.coBorrowerLastName}
								onChange={e => props.onChange({ name: "coBorrowerLastName", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={5}>
							Cell Phone
							<MaskedInput
								required={true}
								className={"ant-input"}
								mask={[/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
								value={props.state.coBorrowerPhone}
								onChange={e => props.onChange({ name: "coBorrowerPhone", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={5}>
							Date Of Birth (YYYY-MM-DD)
							<MaskedInput
								required={true}
								className={"ant-input"}
								mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
								value={props.state.coBorrowerDOB}
								onChange={e => props.onChange({ name: "coBorrowerDOB", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={4}>
							SSN
							<MaskedInput
								required={true}
								className={"ant-input"}
								mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
								value={props.state.coBorrowerSSN}
								onChange={e => props.onChange({ name: "coBorrowerSSN", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={6}>
							Address
							<Input
								required={true}
								value={props.state.coBorrowerAddress}
								onChange={e => props.onChange({ name: "coBorrowerAddress", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={6}>
							City
							<Input
								required={true}
								value={props.state.coBorrowerCity}
								onChange={e => props.onChange({ name: "coBorrowerCity", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={6}>
							State
							<Input
								required={true}
								value={props.state.coBorrowerState}
								onChange={e => props.onChange({ name: "coBorrowerState", value: e.currentTarget.value })}
							/>
						</Col>
						<Col span={6}>
							ZIP
							<MaskedInput
								required={true}
								className={"ant-input"}
								mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
								value={props.state.coBorrowerZip}
								onChange={e => props.onChange({ name: "coBorrowerZip", value: parseFloat(e.currentTarget.value) })}
							/>
						</Col>
					</>
				) : null}
			</Row>
		</>
	);
}

export default Details;
