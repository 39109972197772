import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tag, Typography } from "antd";
import { firestore } from "../../firebase";
import ModalComponent from "./modal";
import AppHeader from "../../components/appHeader";
import SmartTable from "../../components/smartTable";
import moment from "moment";
import { useSelector } from "react-redux";

const SendReferrals = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const employee = useSelector(state => state.auth.uid);
	const employeeName = useSelector(state => state.auth.name);
	// const store = useSelector(state => state);

	const onComponentMountAsync = () => {
		setLoading(true);
		firestore
			.collection("finance-referrals")
			.where("employee", "==", employee)
			.get()
			.then(employee => {
				setData(
					employee.docs.map(item => {
						return {
							id: item.id,
							...item.data(),
						};
					})
				);
				return setLoading(false);
			})
			.catch();
	};

	useEffect(onComponentMountAsync, []);

	const columns = [
		{
			title: "Date",
			dataIndex: "timestamp",
			width: 200,
			sorter: (a, b) => a.timestamp - b.timestamp,
			render: date => moment(date.toDate()).format("ll"),
		},
		{
			title: "Applicant's Name",
			key: "name",
			sorter: (a, b) => a.phone - b.phone,
			render: (_, record) => record.firstName + " " + record.lastName,
		},
		{
			title: "Applicant's Phone",
			dataIndex: "phone",
			sorter: (a, b) => a.phone - b.phone,
		},
		{
			title: "Bonus",
			dataIndex: "bonus",
			sorter: (a, b) => a.bonus - b.bonus,
			render: record => `$${record}`,
		},
		{
			title: "Status",
			dataIndex: "status",
			filters: [
				{
					text: "Pending",
					value: "Pending",
				},
				{
					text: "Paid",
					value: "Paid",
				},
				{
					text: "Approved",
					value: "Approved",
				},
				{
					text: "No Loan",
					value: "No Loan",
				},
			],
			onFilter: (value, record) => record.status.includes(value),
			render: status => {
				let color;
				if (status === "Pending") {
					color = "orange";
				}
				if (status === "Paid") {
					color = "green";
				}
				if (status === "Approved") {
					color = "blue";
				}
				if (status === "No Loan") {
					color = "red";
				}
				return <Tag color={color}>{status}</Tag>;
			},
		},
		{
			title: "Notes",
			dataIndex: "notes",
		},
	];
	return (
		<div style={{ height: "100vh" }}>
			<AppHeader />
			<ModalComponent
				employee={employee}
				employeeName={employeeName}
				store={null}
				refresh={onComponentMountAsync}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
			/>
			<div style={{ padding: 30, paddingTop: 200, backgroundColor: "#FFF", minHeight: "100vh" }}>
				<Row>
					<Col span={12}>
						<Typography.Title>Send Referrals To Discount Finance</Typography.Title>
					</Col>
					<Col align={"right"} span={12}>
						<Button
							onClick={() => {
								setVisible(true);
							}}
							type={"primary"}>
							Add Referral
						</Button>
					</Col>
					<Col span={24}>
						<SmartTable rowKey={data => data.id} loading={loading} dataSource={data} columns={columns} />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SendReferrals;
