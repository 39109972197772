import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
// noinspection ES6CheckImport
import { SRLWrapper } from "simple-react-lightbox";
import { Layout, Row, Col, Typography, Button } from "antd";
import moment from "moment";
import Map from "../components/map";

type Props = {
	uid: string,
	google: any,
	applications: any,
};
type State = {
	loading: boolean,
	location: string,
	files: Array<Object>,
};

class Files extends Component<Props, State> {
	state = {
		loading: false,
		location: "",
		files: [],
	};

	async componentDidMount(): any {
		const { data: files } = await axios.get(`https://us-central1-digital-loans.cloudfunctions.net/app/user/${this.props.uid}/files?metadata=true`, {
			headers: {
				Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c",
			},
		});

		this.setState({ files });

		if (this.props.applications[0].location) {
			const { data: location } = await axios.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.props.applications[0].location.latitude},${this.props.applications[0].location.longitude}&key=AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk`
			);
			this.setState({ location: location.results[0]["formatted_address"] });
		}
	}

	download = () => {
		this.setState({ loading: true });
		return axios
			.get(`https://us-central1-digital-loans.cloudfunctions.net/app/user/${this.props.uid}/files`, {
				headers: {
					Authorization: "92a699dd-b671-4f1e-83bb-f4eea7fa563c",
				},
			})
			.then(res => {
				this.setState({ loading: false });
				return window.open(res.data, "_blank");
			});
	};

	render() {
		const { files } = this.state;
		return (
			<Layout.Content style={{ padding: "30px", minHeight: "92vh" }}>
				<div style={{ background: "#fff", padding: 60, borderRadius: 0 }}>
					<Typography.Title level={1}>Files</Typography.Title>
					{files.length ? (
						<SRLWrapper>
							<Row>
								{files.map(file => (
									<Col span={6} key={file.url}>
										<a href={file.url} data-attribute="SRL">
											<img style={style.responsiveImg} alt={`${file.name}`} src={file.url} />
										</a>
										<br />
										{file.name}
										<br />
										{moment(parseInt(file.timestamp)).format("lll")}
										<br />
										{file.longitude && file.latitude ? (
											<a
												rel="noopener noreferrer"
												href={`https://www.google.com/maps/search/?api=1&query=${file.longitude},${file.latitude}`}
												target={"_blank"}>
												Open in Google Maps
											</a>
										) : null}
										<br />
										<br />
									</Col>
								))}
							</Row>
						</SRLWrapper>
					) : null}
					<Row style={{ marginBottom: 50 }} gutter={15}>
						<Col span={24} style={{ marginTop: 20 }}>
							<Button type="primary" onClick={this.download} loading={this.state.loading}>
								Download Files
							</Button>
						</Col>
					</Row>
					<Typography.Title level={2}>User Location</Typography.Title>
					<Typography.Title level={4}>
						Address: {this.state.location} <br />
						Last Known:{" "}
						{this.props.applications[0].location ? moment(this.props.applications[0].location.timestamp.toDate()).format("lll") : " Unavailable"}
					</Typography.Title>

					{this.props.applications[0].location ? (
						<Map latitude={this.props.applications[0].location.latitude} longitude={this.props.applications[0].location.longitude} />
					) : null}
				</div>
			</Layout.Content>
		);
	}
}

function mapStateToProps() {
	return {};
}

const style = {
	container: {
		width: 200,
		display: "inline",
	},
	responsiveImg: {
		width: 120,
		height: 120,
		margin: 5,
	},
};

//$FlowFixMe
export default connect(mapStateToProps)(Files);
