import { Ability, AbilityBuilder } from "@casl/ability";

// Defines how to detect object's type
function subjectName(item) {
	if (!item || typeof item === "string") {
		return item;
	}
	return item["__type"];
}

const ability = new Ability([], { subjectName });

//$FlowFixMe
function refresh(auth) {
	ability.update(defineRulesFor(auth));
}

function defineRulesFor(auth) {
	const { can, rules } = AbilityBuilder.extract();
	if (auth.role === "Admin") {
		can(["add", "view", "edit", "delete"], "users");
		can(["export"], "csv");
		can(["delete"], "application");
	}

	if (auth.role === "User") {
	}

	return rules;
}

export default {
	ability,
	refresh,
};
