import React from "react";
import moment from "moment";

export const columns = () => [
	{
		title: "Date",
		dataIndex: "date",
		render: (record: string) => <p>{moment(record).format("ll")}</p>,
		// $FlowFixMe
		sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
	},
	{
		title: "Payee",
		dataIndex: "name",
		search: "name",
	},
	{
		title: "Email",
		dataIndex: "email",
		search: "email",
	},
	{
		title: "Phone",
		dataIndex: "phone",
		search: "phone",
	},
	{
		title: "Zips",
		dataIndex: "zip",
		search: "zip",
	},
];
